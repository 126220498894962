import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { CustomerType, getDepartmentIdFromIdent, IVehicleConfiguration, getLocationIDByMake, IConfiguration } from 'next-common';
import {
  IVehicleListOrderProps,
  IVehiclePageProps,
  packageAppendingPath,
  Routes,
  selectedDealerId,
  useEmployee,
} from '../../../common';
import {
  getAppendingIdFromUrl,
  getAppendingNumericIdFromUrl,
  mapEmployeeToUser,
  VehicleContextProvider,
} from '../../../utils';
import { IVehicleDetailsPageProps } from '../../sales/sales-finance-page';
import { IVehicleAccessoriesPageProps } from '../../sales/sales-vehicle-accessories-page';
import { IPages, IVehicleUpdate, SalesWizard } from '../../sales/sales-wizard';
import styles from './SalesWizardPage.module.scss';
import { useMsal } from '@azure/msal-react';

interface ISalesWizardPageProps {
  dealerRole?: string;
  isTestUser?: boolean;
  configuration?: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
  redirectPath?: string
}

const SalesWizardPage = (props: ISalesWizardPageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.PRIVATE);
  const { accounts } = useMsal();
  const employee = useEmployee(accounts?.[0]?.username);

  const getOrderId = (appendingPath = '') => getAppendingNumericIdFromUrl(appendingPath);
  
  useEffect(() => {
    if (!employee) return;
    const currentDealerId = selectedDealerId();
    if (!currentDealerId) {
      const newDealerId = employee?.departmentNumber;
      selectedDealerId(newDealerId);
    }
  }, [employee]);

  const selectedDealer = useReactiveVar(selectedDealerId);

  const getUpdateOrderVariablesFromSelectedVehicle = (vehicle: IVehicleConfiguration): IVehicleListOrderProps => ({
    id: getOrderId(),
    input: {
      customer: { customerType },
      orderReference: { pimId: vehicle?.id, pimCode: vehicle?.sku },
      soldByDealerId:
        vehicle?.model?.isAgent && vehicle?.model?.make
          ? getLocationIDByMake(vehicle?.model?.make)
          : selectedDealer ?? getDepartmentIdFromIdent(employee?.employeeId),
      isAgent: vehicle?.model?.isAgent,
      user: employee ? mapEmployeeToUser(employee, selectedDealer) : null,
      ...(vehicle?.model?.series ? { lead: { modelSeries: vehicle?.model?.series, make: vehicle?.model?.make } } : {}),
      discount: 0,
    },
  });

  const getPickerPageProps = () => ({
    orderId: getOrderId(),
    getUpdateOrderVariables: getUpdateOrderVariablesFromSelectedVehicle,
  });
  const getVehiclePageProps = (appendingPath = ''): IVehiclePageProps => ({
    orderId: getOrderId(appendingPath),
  });

  const getPageProps = (): IVehicleDetailsPageProps => ({
    ...getVehiclePageProps(),
    customerType,
    pimCode: null,
    onCustomerType: (type: CustomerType) => setCustomerType(type),
  });

  const getAccessoriesPageProps = (): IVehicleAccessoriesPageProps => {
    return getVehiclePageProps();
  };

  const packages = {
    ...getVehiclePageProps(packageAppendingPath),
    pimId: getAppendingIdFromUrl(),
  };
  const pageProps: IPages = {
    picker: getPickerPageProps(),
    details: getPageProps(),
    finance: getPageProps(),
    signing: getPageProps(),
    packages,
    accessories: getAccessoriesPageProps(),
  };

  return (
    <div className={styles.salesWizardPage}>
      <VehicleContextProvider>
        <SalesWizard
          dealerRole={props.dealerRole}
          isTestUser={props.isTestUser}
          manageSocket={props.manageSocket}
          orderId={getOrderId()}
          rootUrl={Routes.getSalesWizardPage()}
          pages={pageProps}
          onCustomerType={(type: CustomerType) => {
            setCustomerType(type);
          }}
          getUpdateOrderVariables={getUpdateOrderVariablesFromSelectedVehicle}
          configuration={props.configuration}
          redirectPath={props.redirectPath}
        />
      </VehicleContextProvider>
    </div>
  );
};

export default SalesWizardPage;
