import { OperationVariables, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  ICartHeaderProps,
  ICartProps,
  ICartSummaryData,
  ICartSummaryProps,
  ReserveButtonStages,
} from '@next-components/cart';
import { GlobalStyle } from '@next-components/common-styles';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { debounce, defaultTo, get, isEmpty, isNil, map } from 'lodash';
import {
  CustomerType,
  FinancingType,
  getInteriorCodeFromFactory,
  getInteriorCodeFromOptions,
  getMakeNameByCode,
  getVarnishCodeFromFactory,
  getVarnishCodeFromOptions,
  IApiEmployee,
  IConfiguration,
  IEquipmentTypes,
  ILead,
  ImporterLocationId,
  IOrder,
  IUser,
  IVehicle,
  IVehicleConfiguration,
  IVehicleEquipment,
  MAKE,
  NextErrorCode,
  OrderStatus,
  VehicleEquimentTypeEnum,
  VehicleEquipmentCategoryEnum,
  VehicleReserveStatus,
  isStockSaleOrderToBeProcessed,
  INextArticle,
  HashMap,
  BosOrderStatus,
  ApplicationCode,
  invokeProvidedBrandSpecificCode,
  IUniqueModelCodeAndSkus,
  getStockEquipmentLevelsFromEquipments,
} from 'next-common';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ICacheResponse, MongoEvents } from 'sales-common';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  apimEndpoint,
  currentVehicle,
  ERROR_POLICY,
  getTTCodeStatusName,
  isStockOrder,
  IVehicleListOrderProps,
  IVehiclePageProps,
  IVehicleStock,
  IVehicleStockForSellAllCars,
  IVehicleStockParams,
  IVehicleStockSellAllCars,
  newRoutes,
  Routes as Path,
  removeVehiclesFromOrderMutationParsed,
  sendReserveVehicleOrderMutationParsed,
  serviceNowSupportUrl,
  statusChecked,
  useEmployee,
  useOrder,
  useVehicle,
  useVehicleModels,
  useVatStateCalculationHandler, useServiceContract,
  salesCommonCacheUrl
} from '../../../common';
import {
  findVehicleByPimCode,
  queryOrderWithLead,
  vehicleStockBySerialNoQuery
} from '../../../graphql';
import {
  PeuProductionDate,
  eventEmitter, Events, defaultProductionDate,
  checkVehicleSoldOrReserved,
  convertToString,
  createStockKey,
  fetchVehicleLocationIdList,
  fetchVehicleStatusNamesList,
  fetchVehicleTraceCodeList,
  formatKey,
  getAvailableBaseModel,
  getGroupName,
  getListAfterInitialFilter,
  getMake,
  getModelName,
  getModelSeries,
  getPackageSkus,
  getVehiclesForBaseModel,
  hasReservationBlockingCancellation,
  isArticleBuild,
  isBaseModel,
  LocationNames,
  prepareImageObject,
  removeDuplicateObjectFromArray,
  setCarReservedState,
  IUnavailableVehicleWarning,
  isColorFound,
  isInteriorFound,
  isEquipmentFound,
  checkIfVehicleConfigurationHasChanged,
  prepareInitialArticlesAndCart,
  validateCarDataFromPIM,
  isValidFactoryCodes,
  updateOrderLead,
  getManufactureModelId,
  createOptions,
  updateGrpNameWithModelSeries,
  isSelectedVehileIsUnavailable,
  isOrderEditable,
  validateModelSeriesName,
  getEmployeeDealerIds,
  isVehicleValidForReserveOrSignin,
  getCarTypeOfSelectedModel,
  getStructuredVehicleData,
  getStructuredVehicleModelData,
  mapIsDisableVehicles,
  filterVehicleStockForKia,
  mapSelectabeleEquipment,
  filterByVehicleType, 
  getStockSaleParams,
  isValidStockSaleOrder, 
  mapperForSellAllCars, 
  updateVehicleDataForSellAllCarsOnSubscription,
  getMissingFields
} from '../../../utils';
import vehicleStock from '../../../utils/sales/vehicleStock';
import {
  ErrorNotification,
  NextErrorNotification,
  ReservedWarning,
  ValidationErrorCode,
  VehicleUnavailableNotification,
  FactoryCodeWarning,
} from '../../notifications';
import { CancelReserveOrderModal } from '../sales-common/CancelReserveOrderModal';
import { PageSteps } from '../sales-common/NewHeader';
import { ToastArea } from '../sales-common/ToastArea';
import { FinancePage, IFinancePageProps, IVehicleDetailsPageProps } from '../sales-finance-page';
import { ISigningPageProps, SigningPage } from '../sales-signing-page';
import { IVehicleAccessoriesPageProps, VehicleAccessoriesPage } from '../sales-vehicle-accessories-page';
import {
  IModelPackages,
  IVehicleModel,
  IVehiclePickerPageProps,
  VehiclePickerPage,
} from '../sales-vehicle-picker-page';
import { SalesHeader } from './sales-header';
import { IStepMenuData, SalesWizardMenu } from './sales-wizard-menu';
import { checkExpireOrder } from '../sales-order-view/OrderPane/OrderButtons';
import { HeaderWithSalesSteps, SalesWizardContent, SalesWizardStyled } from './SalesWizard.styled';
import { IVehicleStockWithSegregatedTypes } from './vehicleSegregationWorker';
import { serialNumbersToExclude } from '../../../utils/staticSerialNumber';
import { IconType } from '@next-components/bos-icon';
import { ButtonType, ColorType } from '@next-components/button';
import axios from 'axios';
import { getCachedToken } from '../../../config/authentication';

export interface IPages {
  picker: IVehiclePickerPageProps;
  details: IVehicleDetailsPageProps;
  packages: IVehiclePageProps;
  finance: IFinancePageProps;
  signing: ISigningPageProps;
  accessories: IVehicleAccessoriesPageProps;
}
export interface ISalesWizardProps {
  dealerRole?: string;
  isTestUser?: boolean;
  children?: React.ReactNode;
  pages: IPages;
  order?: IOrder;
  orderId: string;
  user?: IUser;
  rootUrl: string;
  configuration?: IConfiguration;
  onCustomerType(customerType: CustomerType): void;
  getUpdateOrderVariables: (vehicle: IVehicleConfiguration) => IVehicleListOrderProps;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
  redirectPath?: string;
}
export interface IVehicleUpdate {
  data: IVehicleStock;
  operation: string;
}

export interface ICart extends ICartHeaderProps, ICartSummaryProps { }

export interface IActionProps {
  label: string;
  icon?: IconType;
  actionVariant?: ButtonType | string;
  actionColorVariant?: ColorType | string;
  actionMethod: () => void;
}
export interface IAlertPopup {
  title: string;
  description: string;
  actionsList: IActionProps[];
}

const Error404 = () => <h1>Fant ikke siden du ser etter</h1>;

export const SalesWizard = (props: ISalesWizardProps) => {
  const client = useApolloClient();
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);
  const [isCollapsed] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [lead, setLead] = useState<ILead>(null);
  const [showCustomerPopup, setShowCustomerPopup] = useState<boolean>(false);
  const [serialNo] = useState<string>('');
  const [calculationId, setCalculationId] = useState<string>(null);
  const [cartData, setCartData] = useState<ICartProps>(null);
  const [queryMake] = useQueryParam('merke', StringParam);
  const [queryModelSeries] = useQueryParam('modellserie', StringParam);
  const [vehicleData, setVehicleData] = useState<IVehicleModel[]>([]);
  const [vehicleModelData, setVehicleModelData] = useState<IVehicleModel[]>([]);
  const [vehicleColorId, setVehicleColorId] = useState<string>(null);
  const [selectableEquipments, setSelectableEquipments] = useState<IEquipmentTypes[]>([]);
  const [vehicleInteriorId, setVehicleInteriorId] = useState<string>(null);
  const [rimsEquipments, setRimsEquipments] = useState<IEquipmentTypes>(null);
  const [listverkEquipments, setListverkEquipments] = useState<IEquipmentTypes>(null);
  const [taklakkEquipments, setTaklakkEquipments] = useState<IEquipmentTypes>(null);
  const [availableVehicles, setAvailableVehicles] = useState<IVehicleStockWithSegregatedTypes[]>([]);
  const [filteredVehicleList, setFilteredVehicleList] = useState<IVehicleStockWithSegregatedTypes[]>([]);
  const [isVehicleSelected, setIsVehicleSelected] = useState<boolean>(false);
  const [dealerIds, setDealerIds] = React.useState<string[]>([]);
  const [interiorAvailableInPimFlag, setInteriorAvailableInPimFlag] = React.useState<boolean>(false);
  const [colorAvailableInPimFlag, setColorAvailableInPimFlag] = React.useState<boolean>(false);
  const [isVehicleStockQueryCalled, setIsVehiclStockQueryCalled] = React.useState<boolean>(false);
  const [isShowDeleteModel, setIsShowDeleteModel] = useState<boolean>(false);
  const [vehicleAvailabilityCode, setVehicleAvailabilityCode] = useState<string>(null);
  const [removeVehiclesFromOrder] = useMutation(removeVehiclesFromOrderMutationParsed);
  const [dataFromFactoryEquipLevels, setDataFromFactoryEquipLevels] = useState<IUniqueModelCodeAndSkus>(null);
  const importerLocationIds = Object.values<string>(ImporterLocationId);
  const [vehicleLocation, setVehicleLocation] = useState<string>(null);
  const [vehicleStatus, setVehicleStatus] = useState<string>(null);
  const [selectedSerialNo, setSelectedSerialNo] = useState<number>(null);
  const [reserveButtonStage, setReserveButtonStage] = useState<ReserveButtonStages>(
    ReserveButtonStages.NO_RESERVE_REQUEST,
  );
  const [vehicleReservedStatus, setVehicleReservedStatus] = useState<boolean>(false);
  const [isFactoryOrder, setIsFactoryOrder] = useState<boolean>(false);
  const [vehicleVariantId, setVehicleVariantId] = useState<string>(null);
  const [vehicleSku, setVehicleSku] = useState<string>('');
  const [vehicleModelId, setVehicleModelId] = useState<string>(null);
  const [vehicleModelCode, setVehicleModelCode] = useState<string>(null);
  const [, setVehicleListData] = useState<IVehicleConfiguration[]>([]);
  const [group, setGroup] = useState<string>(null);
  const [reserveVehicleOrderMutation] = useMutation(sendReserveVehicleOrderMutationParsed);
  const [reserveCancelModalStatus, setReserveCancelModalStatus] = useState<boolean>(false);
  const [offerMake, setOfferMake] = useState<MAKE>(null);
  const [stepMenuData, setStepMenuData] = useState(null);
  const [modelOptions, setModelOptions] = useState<HashMap>(null);
  const [vehicleStockDataForSellAllCars, setVehicleDataForSellAllCars] = useState<IVehicleStockSellAllCars[]>([]);
  const [isVehicleRemoved, setIsVehicleRemoved] = useState<boolean>(false);

  const order: IOrder = useOrder(props);
  const employee: IApiEmployee = useEmployee();

  const [validLocationIds, setValidLocationIds] = useState<string[]>([]);
  const [isSelectedVehicleUnavailable, setIsSelectedVehicleUnavailable] = useState<boolean>(false);
  const [vehicleKey, setVehicleKey] = useState<string>(null);
  const [isListPrepared, setIsListPrepared] = useState<boolean>(false);
  const [expectedProductionDate, setExpectedProductionDate] = useState(new Date(defaultProductionDate()));
  const [isUnavailablePimData, setIsUnavailablePimData] = useState<boolean>(false);
  const [softLockOrderId, setSoftLockOrderId] = useState<string>(null);
  const [basePrice, setBasePrice] = useState<[number, number]>([0, 0]);
  const [selectedVariantPath, setSelectedVariantPath] = useState<string>(null);
  const [mappedEquipment, setMappedEquipment] = useState<IEquipmentIdAndSKU[]>([]);
  const [isVehicleValidForProceed, setIsVehicleValidForProceed] = useState<boolean>(true);
  const [selectedVehicleStock, setSelectedVehicleStock] = useState<IVehicleStock>(null);
  const [articles, setArticles] = useState<INextArticle[]>([]);
  const [hasVehicleStockConfigurationChanged, setHasVehicleStockConfigurationChanged] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<IModelPackages>(null);
  const notificationsRef = useRef(null);

  const [isPickerLoading, setPickerLoading] = useState<boolean>(false);
  const [vehicleConfigLoading, setVehicleConfigLoading] = useState<boolean>(false);
  const [hasUpdateEventOccurs, setHasUpdateEventOccurs] = useState<boolean>(false);
  const [vehicleList, setVehicleList] = useState<IVehicleConfiguration[]>([]);
  const [exactMatchCount, setExactMatchCount] = useState<number>(0);
  const [nonExactMatchCount, setNonExactMatchCount] = useState<number>(0);
  const [skuDifference, setSkuDifference] = useState<string[]>([]);
  const [selectedModelName, setSelectedModelName] = useState<string>(null);
  const [selectedColorName, setSelectedColorName] = useState<string>(null);
  const [selectedInteriorName, setSelectedInteriorName] = useState<string>(null);
  const [isStockApiCall, setIsStockAPICall] = useState<boolean>(false);
  const [groupNameForStockAPI, setGroupNameForStockAPI] = useState<string>(null);
  const [selectedVehicleStockStatus, setSelectedVehicleStockStatus] = useState<string>('');
  const [isChangeVehicle, setIsChangeVehicle] = useState<boolean>(false);
  const [isStockLoading, setIsStockLoading] = useState<boolean>(false);
  const [missingFieldsWarningMessage, setMissingFieldsWarningMessage] = useState<string>(null);
  const [currentVehicleConfig, setCurrentVehicleConfig] = useState(null);

  let { vehicle, useVehicleLoading } = useVehicle({
    ...props,
    pimCode: order?.orderReference?.pimCode,
    vehicleId: get(order, 'vehicles[0].id', null),
    source: order?.lead?.source,
    currentStep,
    configuration: props?.configuration,
    serialNo: order?.vehicles?.[0]?.serialNo,
    make: order?.lead?.make,
    selectedVariantPath
  });

  const { data: serviceContract, loading: serviceContractLoading } = useServiceContract(
    client,
    {
      serialNo: order?.vehicles?.[0]?.serialNo,
      sku: order?.orderReference?.pimCode,
      activeStep: currentStep,
      createdOrderType: order?.lead?.orderType
    });
  const wizardRef = useRef<IOrder>(undefined);
  const dealerIdsRef = useRef<string[]>(null);
  const navigate = useNavigate();

  const params = getStockSaleParams(order);
  const isStockSaleOrder = isValidStockSaleOrder(order, props.configuration)

  const isStockSaleNEXTOrder = isStockSaleOrderToBeProcessed(props?.configuration, params?.source, null, null);

  useEffect(() => {
    if (order && checkExpireOrder(order?.status)) {
      navigate(Path.getViewPage(order?.id), { replace: true });
    }
    if (props?.redirectPath) {
      navigate(props?.redirectPath);
    }
  }, [order, props?.redirectPath]);

  useEffect(() => {
    // Prepare valid Dealer list
    if (!employee) return;
    const employeeDealerIds: string[] = getEmployeeDealerIds(employee);
    setDealerIds(employeeDealerIds);
    setValidLocationIds([...employeeDealerIds, ...importerLocationIds]);
    dealerIdsRef.current = [...employeeDealerIds, ...importerLocationIds];
  }, [employee]);

  let { vehicleModels, isVehicleListDataLoaded } = useVehicleModels({
    make: getMake(vehicle, queryMake, order),
    modelSeries: order?.lead?.subFamilySeries?.toUpperCase() || getModelSeries(vehicle, queryModelSeries, order),
    source: order?.lead?.source,
    configuration: props?.configuration,
    serialNo: order?.vehicles?.[0]?.serialNo,
    setIsLoading: (loadingState) => {
      if (!loadingState) {
        if ((!vehicleStock?.sizeOfStock() && !isLoading) || (vehicleStock?.sizeOfStock()) || (isLoading && vehicleModels?.results?.length > 0)) {
          setIsLoading(false);
        }
      } else {
        setIsLoading(loadingState);
      }
    }
  });

  useEffect(() => {
    if (!isStockSaleOrder) {
      setModelOptions(createOptions(vehicleModels?.results));
    }
  }, [vehicleModels, isStockSaleOrder, currentStep]);

  useEffect(() => {
    if (!isStockSaleOrder) {
      setVehicleListData(vehicleList);
    }
  }, [vehicleList, isStockSaleOrder, currentStep]);

  useEffect(() => {
    if (isStockApiCall === false && groupNameForStockAPI !== null && !isEmpty(props?.configuration)) {
      setIsLoading(true);
      setIsStockLoading(true);
      if (!isNil(order)) {
        getVehicleStock({ variables: { group: groupNameForStockAPI, isStockApiCall } });
        setIsStockAPICall(true);
      }
    }
  }, [props?.configuration, groupNameForStockAPI, isStockApiCall, order]);

  const getVehicleStock = async ({ variables }) => {
    try {
      const token = await getCachedToken();
      const vehicleStockData = await axios.get(
        `${salesCommonCacheUrl}/cache/getCacheByKey/${variables.group}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      let vehicleStockList = vehicleStockData?.data?.data;
      if (props?.configuration?.features?.vehicleTypeFilter?.length) {
        vehicleStockList = filterByVehicleType(vehicleStockList, props.configuration);
      }
      if (vehicleStockList?.length && isStockOrder(order?.lead?.orderType)) {
        vehicleStockList = vehicleStockList.filter(item => {
          return Object.values(ImporterLocationId).includes(item.locationId);
        });
      }
      if (vehicleStockList?.length) {
        vehicleStock?.clearStock();
        vehicleStock?.setStock('ALL', vehicleStockList);
        let vehicleStockData: IVehicleStockForSellAllCars[] = [];
        vehicleStockList?.forEach((currentVehicle: IVehicleStock) => {
          if (currentVehicle?.vehicleStatus?.vehicleStatus && !serialNumbersToExclude?.includes(currentVehicle?.serialNumber)) {
            vehicleStockData.push({
              ...currentVehicle,
              vehicleStatus: currentVehicle?.vehicleStatus,
              vehicleImages: currentVehicle?.vehicleImages,
              factorySpecifications: currentVehicle?.factorySpecifications,
            });
          }
          // create hashmap
          const key = `${group || ''}-${formatKey(currentVehicle?.modelId) || ''}`;
          if (vehicleStock?.hasStock(key) && !serialNumbersToExclude?.includes(currentVehicle?.serialNumber) && !isEmpty(currentVehicle?.vehicleStatus?.vehicleStatus)) {
            const value = vehicleStock?.getStock(key);
            vehicleStock?.setStock(key, [...value, currentVehicle]);
          } else {
            if (!serialNumbersToExclude?.includes(currentVehicle?.serialNumber) && !isEmpty(currentVehicle?.vehicleStatus?.vehicleStatus)) {
              vehicleStock?.setStock(key, [currentVehicle]);
            }
          }
        });
        const vehicleStockSell: IVehicleStockSellAllCars[] = mapperForSellAllCars(vehicleStockData);
        vehicleStock.setGroup(group);
        setIsVehiclStockQueryCalled(true);
        setVehicleDataForSellAllCars(vehicleStockSell);
        setIsLoading(false);
        setIsListPrepared(true);
      } else {
        setIsListPrepared(true);
        setVehicleDataForSellAllCars([]);
        vehicleStock?.clearStock();
        setIsLoading(false);
      }
      setIsLoading(false);
      setIsStockLoading(false);
      if (vehicleModelCode && vehicleSku && vehicleStock?.sizeOfStock()) {
        const key = createStockKey(group, vehicleModelCode);
        setVehicleKey(key);
      }
    } catch (error) {
      console.log(error);
      setAvailableVehicles([]);
      setIsListPrepared(true);
      setIsLoading(false);
      setIsStockLoading(false);
    }
  };

  const [validatePimSku] = useLazyQuery(findVehicleByPimCode, {
    fetchPolicy: 'network-only',
    errorPolicy: ERROR_POLICY,
    variables: {
      pimCode: order?.orderReference?.pimCode || '',
    },
    onCompleted: (data) => {
      if (!isVehicleRemoved) {
        setIsLoading(false);
      }
      if (data?.vehicleByPimCode && !isNil(order?.orderReference?.pimCode) && order?.orderReference?.pimCode !== data?.vehicleByPimCode?.sku) {
        raiseUnavailableVehicleNotification();
      }
    },
    onError: (error) => {
      console.log(error);
      setIsLoading(false);
      raiseUnavailableVehicleNotification();
    },
  });

  useEffect(() => {
    wizardRef.current = order;
    if (!isEmpty(props?.configuration) && !isEmpty(order) && isStockSaleOrder) {
      const make = getMake(null, null, order);
      const groupName: string = getGroupName(make, getModelSeries(null, null, order));
      const updatedGroupName = groupName ? updateGrpNameWithModelSeries(groupName, make) : '';
      setGroup(updatedGroupName);
      const salesArticle = order?.vehicles?.[0]?.ttCode
        ? order?.pimSnapshot?.salesArticle
        : order?.orderReference?.pimCode?.split(' ')?.[0];
      setVehicleKey(createStockKey(groupName, salesArticle));
      setVehicleSku(order?.orderReference?.pimCode);
      setOfferMake(make);
      vehicle = null;
      setVehicleModelId(null);
      setVehicleVariantId(null);
      setVehicleData([]);
      setIsSelectedVehicleUnavailable(false);
      setVehicleConfigLoading(false);
    }
  }, [order, props?.configuration, isStockSaleOrder]);

  useEffect(() => {
    // vehicle-stock would be updated when global `vehicleStock` state doesn't have group OR
    // `vehicleStock` group doesn't match with existing group (in case when redirection happens back from view page)
    const updatedGroupName = group ? updateGrpNameWithModelSeries(group, getMake(vehicle, queryMake, order)) : '';
    if (updatedGroupName) {
      props.manageSocket(updatedGroupName, receiveVehicleUpdate);
    }
    if ((group !== null && !vehicleStockDataForSellAllCars?.length) || isVehicleRemoved) {
      setGroupNameForStockAPI(updatedGroupName);
    }
    return () => {
      props.manageSocket();
    };
  }, [group, currentStep]);

  useEffect(() => {
    // Prepare list of available vehicles for selected vehicle configuration
    if (availableVehicles?.length) {
      const make: MAKE = offerMake ?? getMake(vehicle, queryMake);
      const selectableSkus: string[] = selectableEquipments?.map((equip) => equip?.sku);
      if (rimsEquipments) selectableSkus?.push(rimsEquipments?.sku);
      if (listverkEquipments) selectableSkus?.push(listverkEquipments?.sku);
      if (taklakkEquipments) selectableSkus?.push(taklakkEquipments?.sku);

      const updatedVehicleList = availableVehicles?.filter(
        (currentVehicle) =>
          isColorFound(currentVehicle, vehicleColorId) &&
          isInteriorFound(currentVehicle, vehicleInteriorId, make) &&
          isEquipmentFound(currentVehicle, selectableSkus, make),
      );
      setFilteredVehicleList(updatedVehicleList);
    } else setFilteredVehicleList([]);
  }, [
    availableVehicles,
    vehicleColorId,
    vehicleInteriorId,
    selectableEquipments,
    rimsEquipments,
    listverkEquipments,
    taklakkEquipments,
  ]);

  // Cart creation logic
  useEffect(() => {
    const { initialArticles, initialCart } = prepareInitialArticlesAndCart(
      order,
      vehicle,
      vehicleColorId,
      vehicleInteriorId,
      props?.configuration,
    );

    const isArticleBuilt = isArticleBuild(initialArticles, order?.accessories?.length ?? false);
    if (isArticleBuilt && (order?.orderReference?.pimCode === vehicle?.sku || isStockSaleOrder)) {
      setArticles(initialArticles);
    }
    if (!isVehicleRemoved) {
      const modelSeries = order?.lead?.subFamilySeries?.toUpperCase() || getModelSeries(null, queryModelSeries, order);
      setCartData({
        ...cartData,
        make: getModelName(vehicle, queryMake, order),
        modelSeries:
          getModelName(vehicle, queryMake, order) !== getMakeNameByCode(MAKE.Smart)
            ? modelSeries
            : validateModelSeriesName(modelSeries),

        data: removeDuplicateObjectFromArray<ICartSummaryData>([...initialCart], 'key'),
        image: prepareImageObject(vehicle?.images?.[0]),
      });
    }

    if (order?.vehicles?.[0]?.orderFromFactoryDetails?.expectedProductionDate) {
      setExpectedProductionDate(new Date(order?.vehicles?.[0]?.orderFromFactoryDetails?.expectedProductionDate));
    } else if(offerMake === MAKE.Peugeot) {
      setExpectedProductionDate(new Date(PeuProductionDate()));
    } else {
      setExpectedProductionDate(new Date(defaultProductionDate()));
    }
  }, [order, vehicle, queryMake, queryModelSeries, vehicleColorId, vehicleInteriorId, selectedVariant]);

  useEffect(() => {
    const modelData: IVehicleModel[] = getStructuredVehicleModelData(vehicleModels?.results);
    modelData?.forEach((model) => {
      //Motor variant count will not available for KIA after KIA Converting table work.
      model.stockCount = invokeProvidedBrandSpecificCode(MAKE.Kia, { order, vehicleConfiguration: vehicle }) ? 0 : getModelCounts(model?.code);
      model?.salesPackages?.forEach((variant: IModelPackages) => {
        variant.isRecommended = variant?.price === 0;
        if (get(variant, 'packageDescriptionContent.includedSub') && variant.isRecommended) {
          variant.packageDescriptionContent.includedSub = 'Inkludert';
        }
        if (variant?.sku === order?.orderReference?.pimCode) {
          setBasePrice([model?.basePrice, variant?.price]);
          setSelectedVariantPath(variant?.path);
        }
      });
    });
    setVehicleModelData(modelData);
  }, [vehicleModels?.results, order?.orderReference?.pimCode, vehicle, vehicleStockDataForSellAllCars]);

  useEffect(() => {
    if (!isNil(vehicle)) {
      setVehicleList([vehicle]);
      updateAvailableVehicleListForKIA([vehicle]);
      const modelData: IVehicleModel[] = getStructuredVehicleData(vehicle);
      modelData?.forEach((model) => {
        model?.salesPackages?.forEach((variant: IModelPackages) => {
          variant.isRecommended = variant?.price === 0;
          if (variant?.sku === order?.orderReference?.pimCode) {
            setMappedEquipment(variant?.mappedEquipment ?? []);
            setSelectedVariant(variant);
          }
        });
      });
      setVehicleData(modelData);
    }
  }, [vehicle, order?.orderReference?.pimCode])


  /**
   * Return the total count of stock from vehicleStock based on model
   * @param modelName string
   * @returns number
   */
  const getModelCounts = (modelName: string): number => {
    const key = `${group || ''}-${formatKey(modelName) || ''}`;
    if (vehicleStock?.getStock(key)?.length) {
      return vehicleStock?.getStock(key)?.length
    }

    return 0;
  };

  const carType = order?.pimSnapshot?.vehicleType ?? getCarTypeOfSelectedModel(vehicleModelData, vehicleModelId);

  const { finalPrice } = useVatStateCalculationHandler({
    articles,
    carType
  });

  useEffect(() => {
    setSelectedModelName(vehicle?.model?.name)
    setVehicleModelId(vehicle?.model?.id);
    setVehicleModelCode(vehicle?.model?.modelCode);
    setVehicleVariantId(vehicle?.salesPackages?.[0]?.id);
    setVehicleSku(vehicle?.sku || order?.orderReference?.pimCode);
    const make = getMake(vehicle, queryMake, order);
    setOfferMake(make);
    const newGroup = getGroupName(make, getModelSeries(vehicle, queryModelSeries));
    const updatedGroupName = newGroup ? updateGrpNameWithModelSeries(newGroup, make) : '';
    if (make && queryModelSeries) {
      setGroup(updatedGroupName);
    } else if (vehicle) {
      setGroup(updatedGroupName);
    }
    setVehicleConfigLoading(false);
  }, [vehicle, queryMake, order]);

  useEffect(() => {
    const currentVehicle = vehicle ?? currentVehicleConfig;
    setMissingFieldsWarningMessage(getMissingFields(order, currentVehicle, vehicleModels?.results, cartData?.data));
  }, [vehicle, order, vehicleModelData, currentVehicleConfig]);

  const raiseUnavailableVehicleNotification = () => {
    onNotificationRaised(
      <VehicleUnavailableNotification
        key="pimDataUnavailable"
        errorCode={ValidationErrorCode.VEHICLE_VARIANT_MISSING_IN_PIM}
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => clearNotifications('pimDataUnavailable')}
      />,
    );
    setIsUnavailablePimData(true);
  };

  useEffect(() => {
    if (order?.orderReference?.pimCode && vehicleModels?.results && !isStockSaleOrder) {
      if (!vehicleModels?.results?.length) {
        raiseUnavailableVehicleNotification();
      } else {
        const carAvalaibleStatus = validateCarDataFromPIM(order?.orderReference, vehicleModels?.results);
        if (!carAvalaibleStatus) {
          raiseUnavailableVehicleNotification();
        }
      }
    }
  }, [order, vehicleModels, isStockSaleOrder]);

  useEffect(() => {
    if (order?.orderReference?.pimCode && !isStockSaleOrder) {
      setIsLoading(true);
      validatePimSku();
    }
  }, [order?.orderReference?.pimCode]);

  useEffect(() => {
    const make = getMake(vehicle, queryMake, order);
    if (isValidFactoryCodes(make, order)) {
      statusChecked({
        ...statusChecked(),
        isNoFactoryCode: false,
      });
    } else {
      if (!isValidFactoryCodes(make, order) && (!statusChecked()?.isNoFactoryCode || currentStep === PageSteps.SIGN)) {
        raiseInvalidFactoryCodesError();
        statusChecked({
          ...statusChecked(),
          isNoFactoryCode: true,
        });
      }
    }
  }, [order]);

  useEffect(() => {
    if (
      (!isStockSaleOrder && vehicleKey && vehicleStockDataForSellAllCars?.length > 0 && !isLoading) ||
      (isStockSaleOrder && vehicleStockDataForSellAllCars?.length > 0)
    ) {
      updateAvailableVehicleList();
    }
  }, [vehicleKey, isLoading, vehicleData]);

  const updateAvailableVehicleListForKIA = (vehicleData: IVehicleConfiguration[] = []) => {
    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { order: order, vehicleConfiguration: vehicleData?.[0] })) {
      const data = getStockEquipmentLevelsFromEquipments(selectableEquipments, vehicleData?.[0]);
      setDataFromFactoryEquipLevels(data);

      const dataForSelectedSalesPackage = getStockEquipmentLevelsFromEquipments([], vehicleData?.[0]);
      const selectedVehicleStock: IVehicleStock[] = filterVehicleStockForKia(dataForSelectedSalesPackage, group, vehicleStock);
      let validVehicleList = [];
      if (selectedVehicleStock?.length) {
        validVehicleList = mapIsDisableVehicles(selectedVehicleStock, validLocationIds, vehicleReservedStatus);
      }
      setAvailableVehicles(validVehicleList);
    }
  };

  const updateAvailableVehicleList = (
    key = vehicleKey,
    sku = vehicleSku,
    modelId = vehicleModelId,
    isReturn = false,
    validLocationIdList = validLocationIds,
  ) => {
    if (offerMake != MAKE.Kia) {
      const selectedVehicleStock = vehicleStock?.getStock(key);
      let validVehicleList = [];
      let packageSkus = getPackageSkus(vehicleModelData, modelId);
      if (isStockSaleOrder) {
        packageSkus = [sku];
      }
      const { isBaseModelAvailable, baseModel } = getAvailableBaseModel(packageSkus, modelOptions);
      if (selectedVehicleStock?.length) {
        if (isBaseModelAvailable && isBaseModel(baseModel, sku)) {
          validVehicleList = getVehiclesForBaseModel(
            packageSkus,
            offerMake,
            selectedVehicleStock,
            validLocationIdList,
            vehicleReservedStatus,
            isBaseModelAvailable,
          );
        } else {
          validVehicleList = perfomInitialFilter(selectedVehicleStock, isBaseModelAvailable, sku, validLocationIdList);
        }
        if (isReturn) return validVehicleList;
        setAvailableVehicles(validVehicleList);
        setTimeout(() => {
          setIsListPrepared(true);
        }, 3000);
      } else {
        if (isReturn) return [];
        setAvailableVehicles([]);
        setIsListPrepared(true);
      }
    }
  };

  const perfomInitialFilter = (
    data: IVehicleStock[],
    isBaseModelAvailable: boolean,
    sku = vehicleSku,
    validLocationIdList = validLocationIds,
  ) =>
    getListAfterInitialFilter(sku, offerMake, data, vehicleReservedStatus, validLocationIdList, isBaseModelAvailable);

  useEffect(() => {
    if (vehicleModelCode && vehicleSku && vehicleStock?.sizeOfStock()) {
      const key = createStockKey(group, vehicleModelCode);
      setVehicleKey(key);
    }
  }, [vehicleModelCode, vehicleSku]);

  useEffect(() => {
    if (!order || !vehicle) return;
    prerequisiteReservationCheck();

    //Showing an error message when customer information is null in Step_1 and Step_4
    checkAndRaiseNotificationOfMissingCustomer();

    if (order?.equipments?.length) {
      orderToLocalStateForAllSelectable();
      return;
    }

    setSelectableEquipments([]);
    setRimsEquipments(null);
    setListverkEquipments(null);
    setTaklakkEquipments(null);
  }, [order, vehicle]);

  const checkAndRaiseNotificationOfMissingCustomer = () => {
    if (currentStep === PageSteps.START || currentStep === PageSteps.FINANACE) {
      if (order?.customer?.bosCustomer === null) {
        onNotificationRaised(
          <ErrorNotification
            key="customerError"
            severityLevel={MessagebarSeverityLevel.ERROR}
            errorCode={ValidationErrorCode.MISSING_CUSTOMER_DETAILS}
            onClose={() => clearNotifications('customerError')}
          />,
        );
      }
    }
  };

  const prerequisiteReservationCheck = () => {
    if (order?.reserveDetails) setVehicleReservedStatus(hasReservationBlockingCancellation(order?.reserveDetails));
  };

  const orderToLocalStateForAllSelectable = () => {
    const mappedSelectable: IEquipmentTypes[] = [];
    let mappedRims: IEquipmentTypes = null;
    let mappedListverk: IEquipmentTypes = null;
    let mappedTaklakk: IEquipmentTypes = null;
    order?.equipments?.forEach((equipment: IVehicleEquipment) => {
      if (equipment?.type === VehicleEquimentTypeEnum.Rims) mappedRims = mapSelectabeleEquipment(equipment);

      if (
        equipment?.type === VehicleEquimentTypeEnum.Selectable &&
        ![VehicleEquipmentCategoryEnum.Listverk, VehicleEquipmentCategoryEnum.Taklakk].includes(
          equipment?.category as VehicleEquipmentCategoryEnum,
        )
      )
        mappedSelectable?.push(mapSelectabeleEquipment(equipment));

      if (
        equipment?.type === VehicleEquimentTypeEnum.Selectable &&
        equipment?.category === VehicleEquipmentCategoryEnum.Listverk
      )
        mappedListverk = mapSelectabeleEquipment(equipment);

      if (
        equipment?.type === VehicleEquimentTypeEnum.Selectable &&
        equipment?.category === VehicleEquipmentCategoryEnum.Taklakk
      )
        mappedTaklakk = mapSelectabeleEquipment(equipment);
    });
    setSelectableEquipments(mappedSelectable);
    setRimsEquipments(mappedRims);
    setListverkEquipments(mappedListverk);
    setTaklakkEquipments(mappedTaklakk);
  };

  useEffect(() => {
    // Raise Notification for Unavailable PIM Data
    if (isStockSaleOrder) {
      setVehicleList(null);
      setVehicleModelData([]);
      vehicle = null;
      return;
    }
    const currentVehicleSku = defaultTo(order?.orderReference?.pimCode, null);
    const isenableLoading = isLoading || useVehicleLoading;
    if (currentVehicleSku && vehicleData?.length && vehicleModelData?.length && vehicleModelId && !isenableLoading) {
      const packageSkus = getPackageSkus(vehicleModelData, vehicleModelId);
      if (packageSkus?.length && currentVehicleSku === vehicle?.sku) {
        if (!packageSkus?.includes(currentVehicleSku)) {
          raisePimNotification(ValidationErrorCode.PACKAGE_SKU_NOT_IN_PIM);
        } else if (vehicleColorId && vehicleInteriorId) {
          const currentVehicleVariant = vehicleData?.length
            ? vehicleData?.[0]?.salesPackages?.filter((variant) => variant?.sku === vehicleSku)
            : [];

          const varnishes = currentVehicleVariant?.[0]?.colors;
          const interiors = currentVehicleVariant?.[0]?.interiors;

          const isColorAvailable = varnishes?.filter((varnish) => varnish?.varnishCode === vehicleColorId);
          const isInteriorAvailable = interiors?.filter((interior) => interior?.interiorCode === vehicleInteriorId);

          const isColorNotAvailableInPim = isEmpty(isColorAvailable);
          const isInteriorNotAvailableInPim = isEmpty(isInteriorAvailable);

          setInteriorAvailableInPimFlag(!isInteriorNotAvailableInPim);
          setColorAvailableInPimFlag(!isColorNotAvailableInPim);
          handleUnavailableFromPim(isColorNotAvailableInPim, isInteriorNotAvailableInPim);
        }
      }
    }
  }, [
    isVehicleSelected,
    vehicleModelId,
    vehicleData,
    vehicleColorId,
    vehicleInteriorId,
    vehicleVariantId,
    isStockSaleOrder,
  ]);

  const handleUnavailableFromPim = (isColorNotAvailableInPim: boolean, isInteriorNotAvailableInPim: boolean): void => {
    if (isColorNotAvailableInPim || isInteriorNotAvailableInPim) {
      raisePimNotification(ValidationErrorCode.COLOR_OR_INTERIOR_NOT_IN_PIM);
    } else {
      setIsUnavailablePimData(false);
    }
  };

  useEffect(() => {
    //check vehicle unavailability on page load and after changing vehicle
    if (
      !isOrderEditable(order, props?.configuration) &&
      isValidStockSaleOrder(order, props.configuration) &&
      isListPrepared &&
      selectedVehicleStock
    ) {
      if (currentStep !== PageSteps.SIGN) {
        getIsReserved();
      }
      setVehicleConfigLoading(false);
    }
  }, [order, isListPrepared, selectedVehicleStock]);

  useEffect(() => {
    if (!isVehicleSelected && filteredVehicleList?.length) {
      const ttCodeList = fetchVehicleTraceCodeList(filteredVehicleList);
      const statusNameList: string[] = fetchVehicleStatusNamesList(ttCodeList, getMake(vehicle, queryMake, order));
      if (statusNameList?.length) setVehicleStatus(convertToString(statusNameList));
      // Get Location Name Listing For Available Vehicles
      const vahicleLocationIdList: string[] = fetchVehicleLocationIdList(filteredVehicleList);
      const locationNameList = fetchVehicleLocationNameList(vahicleLocationIdList);
      if (locationNameList?.length) setVehicleLocation(convertToString(locationNameList));
    }
  }, [filteredVehicleList]);

  const isOwnLocation = (locationId: string) => dealerIds?.includes(locationId?.toString());
  const isImporterLocation = (locationId: string) => importerLocationIds?.includes(locationId?.toString());

  const raisePimNotification = (errorCode: number) => {
    setIsSelectedVehicleUnavailable(true);
    setIsUnavailablePimData(true);
    if (!statusChecked()?.isNoPimData || currentStep === PageSteps.SIGN) {
      onNotificationRaised(
        <ErrorNotification
          key="pimDataUnavailable"
          errorCode={errorCode}
          severityLevel={MessagebarSeverityLevel.ERROR}
          onClose={() => clearNotifications('pimDataUnavailable')}
        />,
      );
      statusChecked({
        ...statusChecked(),
        isNoPimData: true,
      });
    }
  };

  const fetchVehicleLocationNameList = (locationIds: string[]): string[] => {
    if (locationIds?.length) {
      const selectedLocationNameList: string[] = map([...new Set(locationIds)], (location: string) => {
        if (isOwnLocation(location)) return LocationNames.OWN;
        if (isImporterLocation(location)) return LocationNames.PORT;
        return LocationNames.OTHER;
      });
      return selectedLocationNameList;
    }
    return [];
  };

  // FIND SELECTED VEHICLE COLOR & INTERIOR CODE
  const updateVehicleData = (data: IVehicleStock, isStockSaleOrder = false) => {
    const trackTraceCode = isStockSaleOrder ? order?.vehicles?.[0]?.inventory?.trackAndTrace : data?.trackTraceCode;
    if (!isStockSaleOrder) {
      setSoftLockOrderId(data?.orderId ?? null);
      setSelectedVehicleStock(data);
      setSelectedVehicleStockStatus(data?.status);
    }
    resetNotificationStatus();
    if (trackTraceCode) {
      setVehicleStatus(getTTCodeStatusName(getMake(vehicle, queryMake, order), trackTraceCode));
    }
  };

  const [getVehicleData] = useLazyQuery(vehicleStockBySerialNoQuery, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    onCompleted: (data: { vehicleStockBySerialNo: ICacheResponse }) => {
      if (data?.vehicleStockBySerialNo?.data?.length) updateVehicleData(data?.vehicleStockBySerialNo?.data?.[0]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    // Get Name on Status and Location for selected Vehicle
    if (order) {
      const orderHasVehicles = Array.isArray(order?.vehicles) && order?.vehicles?.length > 0;
      if (orderHasVehicles) {
        setIsVehicleSelected(true);
        const vehicleLocationId = order?.vehicles?.[0]?.inventory?.location;
        if (vehicleLocationId) {
          const locationNameList = fetchVehicleLocationNameList([vehicleLocationId]);
          setVehicleLocation(locationNameList?.[0]);
        }
        if (order?.vehicles?.[0]?.serialNo === '') {
          setIsFactoryOrder(true);
          setSelectedSerialNo(null);
          setVehicleLocation(null);
          setVehicleStatus(null);
          if (order?.vehicles?.[0]?.orderFromFactoryDetails?.expectedProductionDate) {
            setExpectedProductionDate(new Date(order?.vehicles?.[0]?.orderFromFactoryDetails?.expectedProductionDate));
          } else setExpectedProductionDate(new Date(defaultProductionDate()));
        } else {
          setSelectedSerialNo(Number(order?.vehicles?.[0]?.serialNo));
          setIsFactoryOrder(false);
          setReserveButtonStage(setCarReservedState(order));
          setExpectedProductionDate(new Date(defaultProductionDate()));
        }
      } else {
        setIsVehicleSelected(false);
        setIsFactoryOrder(false);
        setSelectedSerialNo(null);
      }
    }
  }, [order]);

  useEffect(() => {
    const orderedVehicle: IVehicle[] = order?.vehicles;
    if (orderedVehicle?.[0]?.serialNo === '') {
      const factoryDetail = orderedVehicle?.[0]?.orderFromFactoryDetails;
      setVehicleColorId(getVarnishCodeFromFactory(factoryDetail) || null);
      setVehicleInteriorId(getInteriorCodeFromFactory(factoryDetail) || null);
      resetNotificationStatus();
    } else if (orderedVehicle?.[0]?.serialNo) {
      setVehicleColorId(getVarnishCodeFromOptions(order?.options) || null);
      setVehicleInteriorId(
        order?.options?.[0]?.packageInteriorId || getInteriorCodeFromOptions(order?.options) || null,
      );
      const vehicleSerialNo = orderedVehicle?.[0]?.serialNo;
      if (vehicleKey) {
        const cacheData: IVehicleStock[] = vehicleStock?.getStock(vehicleKey);
        const vehicleData: IVehicleStock[] = cacheData?.filter(
          (vehicle) => vehicle?.serialNumber === Number(vehicleSerialNo),
        );
        if (vehicleData?.length) updateVehicleData(vehicleData?.[0]);
        else if (group) {
          const inputProp: IVehicleStockParams = {
            group,
            serialNo: String(vehicleSerialNo),
            isUnavailableInCache: true,
          };
          getVehicleData({ variables: inputProp });
        }
      }
      if (isStockSaleOrder && group) {
        updateVehicleData(null, true);
      }
    } else if (order?.options?.length > 0) {
      setVehicleColorId(getVarnishCodeFromOptions(order?.options) || null);
      setVehicleInteriorId(
        order?.options?.[0]?.packageInteriorId || getInteriorCodeFromOptions(order?.options) || null,
      );
      setSelectedColorName(order?.options?.[0]?.colorName)
      setSelectedInteriorName(order?.options?.[0]?.interiorName)
    } else {
      setVehicleColorId(null);
      setVehicleInteriorId(null);
      resetNotificationStatus();
    }
  }, [vehicleKey, group, order, selectedVehicleStockStatus]);
  // removed statusList, from the dependency array above useEffect.
  const resetNotificationStatus = () => {
    if (currentStep === PageSteps.START) {
      statusChecked({
        ...statusChecked(),
        isNoPimData: false,
      });
    }
  };

  const getIsReserved = () => {
    const isNotify = isSelectedVehileIsUnavailable(order, null, selectedVehicleStock);
    if (isNotify) {
      setIsSelectedVehicleUnavailable(true);
      if (!statusChecked()?.isNoVehicle) {
        onNotificationRaised(
          <ReservedWarning
            key="reservedWarning"
            message=""
            serialNo={order?.vehicles?.[0]?.serialNo}
            onClose={() => clearNotifications('reservedWarning')}
            severityLevel={MessagebarSeverityLevel.ERROR}
          />,
        );
        statusChecked({
          ...statusChecked(),
          isNoVehicle: true,
        });
      }
    } else {
      setIsSelectedVehicleUnavailable(false);
    }
  };

  const runReserveValidation = (currentVehicleStatus: IUnavailableVehicleWarning) => {
    const newOrder = wizardRef.current;

    const isNotify = isSelectedVehileIsUnavailable(newOrder, currentVehicleStatus) && isEmpty(order?.vehicleOrderStatus?.status) && newOrder?.vehicles?.length > 0;
    if (isNotify) {
      setIsSelectedVehicleUnavailable(true);
      onNotificationRaised(
        <ReservedWarning
          key="reservedWarning"
          {...currentVehicleStatus}
          onClose={() => clearNotifications('reservedWarning')}
          severityLevel={MessagebarSeverityLevel.ERROR}
        />,
      );
    } else {
      setIsSelectedVehicleUnavailable(false);
    }
  };

  const updateStatusOnMongoEvent = (status: string) => {
    setSelectedVehicleStockStatus(status);
  }

  const raiseSoftLockNotification = (currentVehicleStatus) => runReserveValidation(currentVehicleStatus);
  const debouncedNotification = debounce(raiseSoftLockNotification, 100);

  const updateVehicleStatusAndLocation = (vehicle: IVehicleStock, validLocationIds: string[], notification: JSX.Element[]) => {
    const isValidVehicle = isVehicleValidForReserveOrSignin(vehicle, validLocationIds);
    setIsVehicleValidForProceed(isValidVehicle);

    setHasVehicleStockConfigurationChanged(false);
    clearNotifications('reservedWarning', notification);
    setIsSelectedVehicleUnavailable(!isValidVehicle);
    setVehicleAvailabilityCode(vehicle?.availabilityCode);
  }

  const receiveVehicleUpdate = (updatedVehicle: IVehicleUpdate) => {
    let vehiclesList = null;
    setVehicleListData((prev) => {
      vehiclesList = prev;
      return prev;
    });
    let sku: string = null;
    setVehicleSku((prev) => {
      sku = prev;
      return prev;
    });

    let validLocationIdList: string[] = [];
    setValidLocationIds((prev) => {
      validLocationIdList = prev;
      return prev;
    });

    let existingVehicleDataForSellAllCars = null;
    setVehicleDataForSellAllCars((prev) => {
      existingVehicleDataForSellAllCars = prev;
      return prev;
    });

    const event = updatedVehicle?.operation;
    const updatedData = updatedVehicle?.data;
    if (updatedData) {
      // Search Object in HashMap
      const updatedKey = `${group || ''}-${formatKey(updatedData?.modelId) || ''}`;

      let orderSerialNo: string = null;
      setSelectedSerialNo((currentSerialno) => {
        orderSerialNo = currentSerialno?.toString();
        return currentSerialno;
      });
      let notification: JSX.Element[] = [];
      setNotifications((currentNotification) => {
        notification = currentNotification;
        return currentNotification;
      });
      if (event === MongoEvents.DELETE) {
        const currentVehicleStatus = checkVehicleSoldOrReserved(updatedData, order?.id);
        updateStatusOnMongoEvent(updatedData?.status);
        const { serialNo } = currentVehicleStatus;
        setSoftLockOrderId(updatedData?.orderId);
        if (serialNo && serialNo === orderSerialNo) {
          clearNotifications('reservedWarning');
          debouncedNotification(currentVehicleStatus);
        }
      } else if (event === MongoEvents.INSERT || event === MongoEvents.UPDATE) {
        event === MongoEvents.UPDATE && setHasUpdateEventOccurs(true);
        if (orderSerialNo === updatedData?.serialNumber.toString()) {
          updateStatusOnMongoEvent(updatedData?.status);
          if (updatedData?.locationId) {
            updateVehicleStatusAndLocation(updatedData, dealerIdsRef?.current, notification)
          }
          if (checkIfVehicleConfigurationHasChanged(
            updatedData,
            order || wizardRef?.current,
            skuDifference,
            setSkuDifference,
          )) {
            onNotificationRaised(
              <ErrorNotification
                key="vehicleStockConfigurationError"
                severityLevel={MessagebarSeverityLevel.ERROR}
                errorCode={ValidationErrorCode.VEHICLE_STOCK_CONFIGURATION_HAS_CHANGED}
                onClose={() => clearNotifications('vehicleStockConfigurationError')}
              />,
            );
            setHasVehicleStockConfigurationChanged(true);
          }
        }
      }

      //Update vehicleStock Data For Sell AllCars 
      updateVehicleDataForSellAllCarsOnSubscription(event, updatedData, existingVehicleDataForSellAllCars, setVehicleDataForSellAllCars);

      // Check if Received Update Effect Current Count
      setVehicleKey((prev) => {
        if (updatedKey === prev) updateAvailableVehicleList(prev, sku, vehicleModelId, false, validLocationIdList);
        return prev;
      });

      if (updatedKey === currentVehicle()?.key) {
        eventEmitter.dispatch(Events.UPDATE_VEHICLE_LIST, true);
      }
    }
  };

  const onNotificationRaised = (notification: JSX.Element) => {
    if (notification.key) {
      const findKeyInNotificationRef = notificationsRef?.current?.find((notify) => notify?.key === notification?.key);
      if (!findKeyInNotificationRef) {
        notificationsRef.current = [...(notificationsRef?.current || []), notification];
        setNotifications(notificationsRef?.current);
      }
    }
  };

  const clearNotifications = (key?: string, notification = notifications) => {
    if (key) {
      const findKeyInNotification = notificationsRef.current?.find((notify) => notify?.key === key);
      if (findKeyInNotification) {
        const afterClear = notificationsRef.current?.filter((notification) => notification?.key !== key);
        notificationsRef.current = afterClear;
        setNotifications(afterClear);
      }
    }
  };
  const confirmReserveCancelClick = async () => {
    setReserveCancelModalStatus(false);
    await reserveOrCancelOrder(VehicleReserveStatus.WAITING_CANCELLATION);
  };

  const onReserveOrCancelOrder = async () => {
    if (reserveButtonStage === ReserveButtonStages.RESERVED) {
      setReserveCancelModalStatus(true);
    }
    if (reserveButtonStage === ReserveButtonStages.NO_RESERVE_REQUEST) {
      await reserveOrCancelOrder(VehicleReserveStatus.WAITING);
    }
  };

  const reserveOrCancelOrder = async (vehicleReserveStatus: VehicleReserveStatus) => {
    eventEmitter.dispatch(Events.DISABLE_RESERVE_BTN, true);
    try {
      const reserveOrder = await reserveVehicleOrderMutation({
        variables: {
          id: order.id,
          status: VehicleReserveStatus[vehicleReserveStatus],
          customerId: order.customer?.reference?.bosApiId,
        },
      });
      setIsLoading(false);
      eventEmitter.dispatch(Events.DISABLE_RESERVE_BTN, false);
      if (reserveOrder?.data?.sendReserveVehicleOrder) {
        navigate(Path.getViewPage(order.id));
      } else {
        raiseReservationFailError();
      }
    } catch (error) {
      eventEmitter.dispatch(Events.DISABLE_RESERVE_BTN, false);
      raiseReservationFailError();
    }
  };
  const onClick = (stepData: IStepMenuData = null) => {
    if (get(stepData, 'route')) {
      setIsCalled(true);
      setStepMenuData(stepData);
      return;
    }
    setStepMenuData(null);
    setIsCalled(true);
  };

  const onClickSigning = (stepData: IStepMenuData) => {
    setStepMenuData(stepData);
    setIsCalled(!isCalled);
  };

  const showAddCustomerPopup = (value: boolean) => {
    setShowCustomerPopup(value);
  };

  const [getOrderWithLead] = useLazyQuery(queryOrderWithLead, {
    errorPolicy: ERROR_POLICY,
    onCompleted: (data) => {
      if (data?.findOrderById) {
        setLead(updateOrderLead(data?.findOrderById?.lead));
        props.onCustomerType(data.findOrderById?.customer?.customerType);
      }
    },
  });

  useEffect(() => {
    getOrderWithLead({ variables: { id: props.orderId ?? '0' } });
  }, []);

  useEffect(() => {
    if (validLocationIds?.length > 0) {
      const isValidVehicle = isStockSaleOrder ? isVehicleValidForReserveOrSignin(selectedVehicleStock, validLocationIds, order) : true;
      setIsVehicleValidForProceed(isValidVehicle);
    }
    if (selectedVehicleStock?.hasOwnProperty('priceOptions') && order && order?.vehicles?.length) {
      if (selectedSerialNo === selectedVehicleStock?.serialNumber) {
        if (checkIfVehicleConfigurationHasChanged(selectedVehicleStock, order, skuDifference, setSkuDifference)) {
          setHasVehicleStockConfigurationChanged(true);
        } else {
          setHasVehicleStockConfigurationChanged(false);
        }
      }
    }
  }, [selectedVehicleStock, validLocationIds, order]);

  useEffect(() => {
    setVehicleAvailabilityCode(selectedVehicleStock?.availabilityCode);
  }, [selectedVehicleStock])

  useEffect(() => {
    if (currentStep === PageSteps.SIGN && hasVehicleStockConfigurationChanged) {
      raiseConfigurationChangeError();
    }
    const make = getMake(vehicle, queryMake, order);
    if (currentStep === PageSteps.SIGN && !isValidFactoryCodes(make, order)) {
      raiseInvalidFactoryCodesError();
    }
  }, [currentStep]);

  useEffect(() => {
    if (hasVehicleStockConfigurationChanged) {
      raiseConfigurationChangeError();
    } else {
      setIsSelectedVehicleUnavailable(false);
      clearNotifications('vehicleStockConfigurationError');
    }
  }, [hasVehicleStockConfigurationChanged]);

  const raiseConfigurationChangeError = () => {
    if (isStockSaleOrder) {
      onNotificationRaised(
        <Messagebar
          key={'vehicleStockConfigurationError'}
          severityLevel={MessagebarSeverityLevel.ERROR}
          title={`Konfigurasjonen misforhold: ${skuDifference?.join(', ')}`}
          onClose={() => clearNotifications('vehicleStockConfigurationError')}
        />,
      );
    } else {
      onNotificationRaised(
        <ErrorNotification
          key="vehicleStockConfigurationError"
          severityLevel={MessagebarSeverityLevel.ERROR}
          errorCode={ValidationErrorCode.VEHICLE_STOCK_CONFIGURATION_HAS_CHANGED}
          onClose={() => clearNotifications('vehicleStockConfigurationError')}
        />,
      );
    }
    setIsSelectedVehicleUnavailable(true);
  };

  const raiseInvalidFactoryCodesError = () => {
    onNotificationRaised(
      <FactoryCodeWarning
        key="factoryCodeUnavailable"
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => clearNotifications('factoryCodeUnavailable')}
      />,
    );
  };

  const raiseReservationFailError = () => {
    onNotificationRaised(
      <NextErrorNotification
        key="reservedFail"
        errorCode={NextErrorCode.VEHICLE_RESERVE_ERROR}
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => clearNotifications('reservedFail')}
      />,
    );
  };

  const isElectric = carType === ApplicationCode.ELECTRIC;
  const hasBeenRedirectedToStartPageForLeadSale = () => decodeURIComponent(window.location.href)?.includes(lead?.make);

  const getPickerPagePropsMemo = React.useMemo(() => {
    return {
      cartData,
      vehicleList,
      vehicleData: isStockSaleOrder ? [] : vehicleData,
      selectedColorId: vehicleColorId,
      selectedInteriorId: vehicleInteriorId,
      selectableEquipments,
      rimsEquipments,
      listverkEquipments,
      taklakkEquipments,
      selectedSerialNo,
      offerMake,
      availableVehicles,
      filteredVehicleList,
      group,
      vehicleSku,
      vehicleReservedStatus,
      validLocationIds,
      vehicleStock,
      vehicleModelCode,
      vehicleModelId,
      vehicleVariantId,
      expectedProductionDate,
      order,
      colorAvailableInPimFlag,
      interiorAvailableInPimFlag,
      basePrice,
      mappedEquipment,
      isVehicleStockQueryCalled,
      selectedModelName,
      selectedVariant,
      selectedColorName,
      selectedInteriorName,
      isOwnLocation,
      isImporterLocation,
      setIsLoading,
    };
  }, [
    availableVehicles,
    basePrice,
    cartData,
    colorAvailableInPimFlag,
    expectedProductionDate,
    filteredVehicleList,
    group,
    interiorAvailableInPimFlag,
    isOwnLocation,
    isImporterLocation,
    mappedEquipment,
    offerMake,
    order,
    rimsEquipments,
    selectableEquipments,
    listverkEquipments,
    taklakkEquipments,
    selectedSerialNo,
    validLocationIds,
    vehicleColorId,
    vehicleData,
    vehicleInteriorId,
    vehicleList,
    vehicleModelCode,
    vehicleModelId,
    vehicleReservedStatus,
    vehicleSku,
    vehicleVariantId,
    isVehicleStockQueryCalled,
    selectedModelName,
    selectedVariant,
    selectedColorName,
    selectedInteriorName
  ]);

  const pageProps = () => ({
    isCalled,
    reserveButtonStage,
    isLoading: isLoading || (order && !isStockSaleOrder && !vehicleList) || !cartData || !cartData?.data,
    isFactoryOrder,
    isVehicleSelected,
    vehicleLocation,
    vehicleStatus,
    isSelectedVehicleUnavailable,
    isUnavailablePimData,
    articles,
    finalPrice,
    onReserveOrCancelOrder,
    raiseNotification: onNotificationRaised,
    clearNotifications,
    setIsCalled,
    stepMenuData,
    order,
    calculationId,
    activeStep: currentStep || 0,
    menuLoading: isLoading || isPickerLoading || useVehicleLoading || vehicleConfigLoading || isVehicleListDataLoaded,
    orderId: props.orderId,
    onClickSigning,
    onClick,
    exactMatchCount,
    nonExactMatchCount,
    isElectricCar: isElectric,
    offerMake,
    isVehicleValidForReserveOrSign: isVehicleValidForProceed,
    vehicleAvailabilityCode
  });

  const pagePropsMemo = React.useMemo(() => {
    return {
      isCalled,
      reserveButtonStage,
      isLoading: isLoading || (order && !isStockSaleOrder && !vehicleList) || !cartData || !cartData?.data,
      isFactoryOrder,
      isVehicleSelected,
      vehicleLocation,
      vehicleStatus,
      isSelectedVehicleUnavailable,
      isUnavailablePimData,
      articles,
      finalPrice,
      onReserveOrCancelOrder,
      raiseNotification: onNotificationRaised,
      clearNotifications,
      setIsCalled,
      order,
      calculationId,
      activeStep: currentStep || 0,
      menuLoading: isLoading || isPickerLoading || useVehicleLoading || vehicleConfigLoading || isVehicleListDataLoaded,
      orderId: props.orderId,
      onClickSigning,
      onClick,
      isElectricCar: isElectric,
    };
  }, [
    cartData,
    finalPrice,
    clearNotifications,
    isCalled,
    isFactoryOrder,
    isLoading,
    isSelectedVehicleUnavailable,
    isUnavailablePimData,
    isVehicleSelected,
    onNotificationRaised,
    onReserveOrCancelOrder,
    reserveButtonStage,
    vehicleList,
    vehicleLocation,
    vehicleStatus,
    order,
    calculationId,
    currentStep,
    isVehicleRemoved
  ]);

  const getSelectedOffer = (calculationIdentification: string) => {
    setCalculationId(calculationIdentification);
  };

  const previousStep = () => {
    const route = Path.getVehiclePickerPage(order?.id);
    setIsCalled(false);
    navigate(route);
  };

  const onRemoveVehicleFromOrder = async (order: IOrder) => {
    setIsLoading(true);
    let variables: OperationVariables = {
      orderId: order?.id,
      options: null,
      vehicles: null,
      vatCalculation: null,
    };
    const { data } = await removeVehiclesFromOrder({ variables });
    if (data?.removeVehiclesFromOrder?.__typename === 'NextError') {
      onNotificationRaised(
        <NextErrorNotification
          key="removeVehicleFail"
          orderId={order?.id}
          errorCode={data?.removeVehiclesFromOrder?.errorCode}
          onClose={() => clearNotifications('removeVehicleFail')}
        />,
      );
    } else {
      setIsVehicleRemoved(true);
      const modelSeries = order?.lead?.subFamilySeries?.toUpperCase() || getModelSeries(vehicle, queryModelSeries, order);
      setCartData({
        ...cartData,
        make: getModelName(vehicle, queryMake, order),
        modelSeries:
          getModelName(vehicle, queryMake) !== getMakeNameByCode(MAKE.Smart)
            ? modelSeries
            : validateModelSeriesName(modelSeries),
        data: [],
        image: null,
      });
      setRimsEquipments(null);
      setSelectableEquipments([]);
      setListverkEquipments(null);
      setTaklakkEquipments(null);
      setSelectedVehicleStock(null);
      setSelectedVariantPath(null)
      previousStep();
    }
  };

  const isVehicleOrderNotSuccess = order?.vehicleOrderStatus
    ? order?.vehicleOrderStatus?.status !== BosOrderStatus[BosOrderStatus.SUCCESS]
    : true;

  const isVehicleNotReserved = order?.reserveDetails
    ? order?.reserveDetails?.vehicleReserveStatus?.toString() !== VehicleReserveStatus[VehicleReserveStatus.RESERVED]
    : true;

  const showAbortFactoryOrderAction = () => {
    return order?.status?.toString() === OrderStatus[OrderStatus.CONFIRMED]
      ? isVehicleOrderNotSuccess
      : isVehicleNotReserved;
  };

  const deleteOrderData: IAlertPopup = {
    title: `Er du sikker på at du vil fjerne valgt bil?`,
    description:
      'Dersom du fjerner valgt bil sendes du tilbake til steg 1 for å konfigurere en ny bil eller velge lagerbil på nytt.',
    actionsList: [
      {
        label: 'Ja, fjern valgt bil',
        actionMethod: () => {
          onRemoveVehicleFromOrder(order);
          setIsShowDeleteModel(false);
        },
      },
      {
        label: 'Avbryt',
        actionMethod: () => setIsShowDeleteModel(false),
        actionVariant: 'secondary',
      },
    ],
  };

  const getRoutes = () => (
    <Routes>
      <Route
        path={Path.getNewVehiclePickerPage()}
        element={
          <VehiclePickerCustomPage
            exactMatchCount={exactMatchCount}
            vehicleModelData={vehicleModelData}
            nonExactMatchCount={nonExactMatchCount}
            setExactMatchCount={setExactMatchCount}
            setNonExactMatchCount={setNonExactMatchCount}
            raiseNotification={onNotificationRaised}
            clearNotifications={clearNotifications}
            isCalled={isCalled}
            vehicle={vehicle}
            lead={lead}
            hasBeenRedirectedToStartPageForLeadSale={hasBeenRedirectedToStartPageForLeadSale}
            pageProps={pagePropsMemo}
            getPickerPageProps={getPickerPagePropsMemo}
            client={client}
            setPickerLoading={setPickerLoading}
            isPickerLoading={isPickerLoading}
            setVehicleConfigLoading={setVehicleConfigLoading}
            vehicleConfigLoading={vehicleConfigLoading || useVehicleLoading}
            hasUpdateEventOccurs={hasUpdateEventOccurs}
            setHasUpdateEventOccurs={setHasUpdateEventOccurs}
            stepMenuData={stepMenuData}
            modelOptions={modelOptions}
            vehicleStockDataForSellAllCars={vehicleStockDataForSellAllCars}
            isVehicleListDataLoaded={isVehicleListDataLoaded}
            setIsLoading={setIsLoading}
            isLoading={isLoading || isStockLoading}
            isShowDeleteModel={isShowDeleteModel}
            setIsShowDeleteModel={setIsShowDeleteModel}
            isShowDeleteButton={!isStockSaleNEXTOrder ? showAbortFactoryOrderAction() : false}
            deleteOrderData={deleteOrderData}
            dealerIds={dealerIds}
            importerLocationIds={importerLocationIds}
            isVehicleRemoved={isVehicleRemoved}
            setIsVehicleRemoved={setIsVehicleRemoved}
            isVehicleValidForReserveOrSign={isVehicleValidForProceed}
            vehicleAvailabilityCode={vehicleAvailabilityCode}
            dataFromFactoryEquipLevels={dataFromFactoryEquipLevels}
            onClickSigning={onClickSigning}
            setIsChangeVehicle={setIsChangeVehicle}
            setCurrentVehicleConfig={setCurrentVehicleConfig}
            {...props}
          />
        }
      />
      <Route
        path={newRoutes.vehicleAccessories}
        element={
          <VehicleAccessoriesPage
            {...{ ...pageProps(), ...props.pages?.accessories }}
            setIsLoading={setIsLoading}
            cartData={cartData}
            availableVehicleList={filteredVehicleList}
            selectedSerialNo={selectedSerialNo}
            expectedProductionDate={expectedProductionDate}
            order={getPickerPagePropsMemo?.order}
            vehicle={vehicle}
            vatPrices={finalPrice}
            isShowDeleteModel={isShowDeleteModel}
            setIsShowDeleteModel={setIsShowDeleteModel}
            isShowDeleteButton={!isStockSaleNEXTOrder ? showAbortFactoryOrderAction() : false}
            isLoading={isLoading}
            deleteOrderData={deleteOrderData}
            configuration={props.configuration}
            onClickSigning={onClickSigning}
            rootUrl={props?.rootUrl}
            isVehicleValidForReserveOrSign={isVehicleValidForProceed}
            vehicleAvailabilityCode={vehicleAvailabilityCode}
          />
        }
      />
      <Route
        path={newRoutes.finance}
        element={
          <FinancePage
            {...{ ...pageProps(), ...props.pages?.finance }}
            raiseNotification={onNotificationRaised}
            clearNotifications={clearNotifications}
            isCalled={isCalled}
            cartData={cartData}
            articles={articles}
            vatPrices={finalPrice}
            client={client}
            selectedSerialNo={selectedSerialNo}
            availableVehicleList={filteredVehicleList}
            setIsLoading={setIsLoading}
            getSelectedOffer={getSelectedOffer}
            setIsCalled={setIsCalled}
            mappedEquipment={mappedEquipment}
            order={getPickerPagePropsMemo?.order}
            vehicle={vehicle}
            serviceContract={serviceContract}
            serviceContractLoading={serviceContractLoading}
            configuration={props.configuration}
            manufactureModelId={getManufactureModelId(vehicleData, vehicleModelId, vehicleVariantId)}
            expectedProductionDate={expectedProductionDate}
            rootUrl={props?.rootUrl}
            isShowDeleteModel={isShowDeleteModel}
            setIsShowDeleteModel={setIsShowDeleteModel}
            isShowDeleteButton={!isStockSaleNEXTOrder ? showAbortFactoryOrderAction() : false}
            isLoading={isLoading}
            deleteOrderData={deleteOrderData}
            dealerRole={props?.dealerRole}
          />
        }
      />
      <Route
        path={newRoutes.signing}
        element={
          <SigningPage
            {...{ ...pageProps(), ...props.pages?.signing }}
            exactMatchCount={exactMatchCount}
            nonExactMatchCount={nonExactMatchCount}
            cartData={cartData}
            finalPrice={finalPrice}
            raiseNotification={onNotificationRaised}
            clearNotifications={clearNotifications}
            showCustomerPopup={showCustomerPopup}
            setShowCustomerPopup={showAddCustomerPopup}
            availableVehicles={availableVehicles}
            selectedSerialNo={selectedSerialNo}
            availableVehicleList={filteredVehicleList}
            isLoading={isLoading || !isListPrepared}
            isUnavailablePimData={isUnavailablePimData}
            softLockOrderId={softLockOrderId}
            vehicleUnavailable={isSelectedVehicleUnavailable}
            order={getPickerPagePropsMemo?.order}
            vehicle={vehicle}
            serviceContract={serviceContract}
            serviceContractLoading={serviceContractLoading}
            configuration={props.configuration}
            isElectricCar={isElectric}
            expectedProductionDate={expectedProductionDate}
            selectedVehicleStockStatus={selectedVehicleStockStatus}
            selectedVehicleStock={selectedVehicleStock}
            isTestUser={props.isTestUser}
          />
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );

  return (
    <>
      <GlobalStyle />
      <SalesWizardStyled>
        <div className="hiddenDiv" />
        <HeaderWithSalesSteps>
          <SalesHeader
            {...props.pages}
            activeStep={currentStep || 0}
            showCustomerPopup={showCustomerPopup}
            setShowCustomerPopup={showAddCustomerPopup}
            serialNo={serialNo}
            vatPrices={finalPrice}
            articles={articles}
            client={client}
            order={order}
            vehicle={vehicle}
            serviceContract={serviceContract}
            configuration={props?.configuration}
          />
          <SalesWizardMenu
            orderId={props.orderId}
            rootUrl={props.rootUrl}
            activeStep={currentStep || 0}
            updateStep={setCurrentStep}
            menuLoading={
              isLoading || isPickerLoading || useVehicleLoading || vehicleConfigLoading || isVehicleListDataLoaded
            }
            onClick={onClick}
            hasSelectedVehicle={!!vehicle || (isStockSaleOrder && !!selectedSerialNo)}
            order={order}
            calculationId={calculationId}
            onClickSigning={onClickSigning}
            isUnavailablePimData={isUnavailablePimData}
            configuration={props?.configuration}
            isVehicleValidForReserveOrSign={isVehicleValidForProceed}
            vehicleAvailabilityCode={vehicleAvailabilityCode}
            isChange={isChangeVehicle}
            isFactoryOrder={isFactoryOrder}
          />
        </HeaderWithSalesSteps>
        <SalesWizardContent isCollapsed={isCollapsed}>
          {!isEmpty(missingFieldsWarningMessage) && (
            <Messagebar
              messageBoxInfo
              className='missing-warning'
              severityLevel={MessagebarSeverityLevel.WARNING}
              title={
                <p className='warning-text'>
                  {missingFieldsWarningMessage}
                  <a className='warning-link' href={serviceNowSupportUrl} target="_blank">
                    Rapporter feilen i ServiceNow
                  </a>
                </p>
              }
            />
          )}
          {getRoutes()}
        </SalesWizardContent>
        <ToastArea notifications={notifications}></ToastArea>
        {reserveCancelModalStatus && (
          <CancelReserveOrderModal
            modalStatus={reserveCancelModalStatus}
            closeModalClick={() => setReserveCancelModalStatus(false)}
            confirmReserveCancelClick={confirmReserveCancelClick}
            currentStep={currentStep || 0}
          />
        )}
      </SalesWizardStyled>
    </>
  );
};

const VehiclePickerCustomPage = ({
  lead,
  hasBeenRedirectedToStartPageForLeadSale,
  pageProps,
  getPickerPageProps,
  vehicle,
  client,
  isCalled,
  stepMenuData,
  modelOptions,
  vehicleStockDataForSellAllCars,
  isLoading,
  setIsLoading,
  isShowDeleteModel,
  setIsShowDeleteModel,
  isShowDeleteButton,
  deleteOrderData,
  isVehicleListDataLoaded,
  importerLocationIds,
  dealerIds,
  isVehicleRemoved,
  setIsVehicleRemoved,
  isVehicleValidForReserveOrSign,
  vehicleAvailabilityCode,
  vehicleModelData,
  onClickSigning,
  dataFromFactoryEquipLevels,
  setIsChangeVehicle,
  setCurrentVehicleConfig,
  ...props
}) =>
  lead && !hasBeenRedirectedToStartPageForLeadSale() ? (
    !isCalled && <Navigate to={Path.getVehiclePickerPage(props.orderId, props.rootUrl, lead)} />
  ) : (
    <VehiclePickerPage
      {...{ orderId: props?.orderId, ...pageProps, ...props?.pages?.picker, rootUrl: props?.rootUrl }}
      exactMatchCount={props?.exactMatchCount}
      nonExactMatchCount={props?.nonExactMatchCount}
      setExactMatchCount={props?.setExactMatchCount}
      setNonExactMatchCount={props?.setNonExactMatchCount}
      raiseNotification={props.raiseNotification}
      clearNotifications={props.clearNotifications}
      pageProps={getPickerPageProps}
      vehicle={vehicle}
      client={client}
      setPickerLoading={props?.setPickerLoading}
      isPickerLoading={props?.isPickerLoading}
      vehicleConfigLoading={props?.vehicleConfigLoading}
      setVehicleConfigLoading={props?.setVehicleConfigLoading}
      hasUpdateEventOccurs={props?.hasUpdateEventOccurs}
      setHasUpdateEventOccurs={props?.setHasUpdateEventOccurs}
      configuration={props.configuration}
      stepMenuData={stepMenuData}
      modelOptions={modelOptions}
      vehicleStockDataForSellAllCars={vehicleStockDataForSellAllCars}
      isVehicleListDataLoaded={isVehicleListDataLoaded}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      isShowDeleteModel={isShowDeleteModel}
      setIsShowDeleteModel={setIsShowDeleteModel}
      isShowDeleteButton={isShowDeleteButton}
      deleteOrderData={deleteOrderData}
      dealerIds={dealerIds}
      importerLocationIds={importerLocationIds}
      isVehicleRemoved={isVehicleRemoved}
      setIsVehicleRemoved={setIsVehicleRemoved}
      isVehicleValidForReserveOrSign={isVehicleValidForReserveOrSign}
      vehicleAvailabilityCode={vehicleAvailabilityCode}
      vehicleModelData={vehicleModelData}
      onClickSigning={onClickSigning}
      dataFromFactoryEquipLevels={dataFromFactoryEquipLevels}
      setIsChangeVehicle={setIsChangeVehicle}
      setCurrentVehicleConfig={setCurrentVehicleConfig}
    />
  );

SalesWizard.defaultProps = {
  order: {
    id: null,
    createdAt: '',
    offers: [],
    chosenOffer: null,
    comments: [],
    contracts: null,
    customer: {
      customerType: CustomerType.PRIVATE,
      hasTyresHotel: false,
      isValidated: true,
    },
    financingType: FinancingType.LOAN,
    insurance: null,
    orderReference: null,
    status: OrderStatus.CREATED,
    user: null,
    vehicles: null,
    timestamp: 0,
  },
  rootUrl: '',
  user: null,
};
