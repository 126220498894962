import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@next-components/cta';
import { MissingItem } from '../../sales-common/DynamicCols/MissingItem';
import { Routes, isStockOrder } from '../../../../common';
import { SalesWizardMenuWrapper, StyledSalesWizardMenu } from './SalesWizardMenu.styled';
import { ISalesWizardButtonMenu, SalesWizardButtonMenu } from './SalesWizardButtonMenu';
import { PageSteps } from '../../sales-common/NewHeader';
import { VehicleContext, isValidStockSaleOrder } from '../../../../utils';
import { isEditable, OrderCreationType } from 'next-common';

export const menuTitles = {
  Vehicle: 'Konfigurer bil',
  VehicleAccessories: 'Tilbehør',
  Finance: 'Finansiering og tjenester',
  Signing: 'Signering',
};

enum WizardSteps {
  Vehicle,
  VehicleAccessories,
  Finance,
  Signing,
}

export interface ISalesWizardMenuProps extends ISalesWizardButtonMenu {
  rootUrl: string;
  updateStep: React.Dispatch<React.SetStateAction<number>>;
  hasSelectedVehicle: boolean;
}

export interface IStepMenuData {
  activeStep: number;
  route: string;
  routeIsActive: boolean;
  routeIsActiveClass: string;
  routeName: string;
}

const getStepsKeys = () => Object.keys(WizardSteps).filter((key) => Number.isNaN(parseInt(key, 10)));

const getStepRoute = (step: string, orderId = '', rootUrl = '') => {
  switch (step) {
    case 'VehicleAccessories':
      return Routes.getVehicleAccessoriesPage(orderId, rootUrl);
    case 'Finance':
      return Routes.getFinancePage(orderId, rootUrl);
    case 'Signing':
      return Routes.getSigningPage(orderId, rootUrl);
    case 'Vehicle':
    default:
      return Routes.getVehiclePickerPage(orderId, rootUrl);
  }
};

const isMenuItemActive = (path: string) => window?.location?.pathname?.includes(path);

export const getActiveStep = (rootUrl = ''): WizardSteps => {
  const activeStepList = getStepsKeys()
    ?.map((key) => (isMenuItemActive(getStepRoute(key, '', rootUrl)) ? key : null))
    ?.filter((item) => !!item);
  return WizardSteps[activeStepList[0]];
};

export const getStepData = (activeStep: number, orderId: string = '', rootUrl: string = '') => {
  return getStepsKeys()?.map?.((key, index) => {
    const routeIsActive = index === activeStep;
    const routeIsActiveClass = routeIsActive ? 'active' : 'inactive';
    return {
      activeStep,
      route: getStepRoute(key, orderId, rootUrl),
      routeIsActive,
      routeIsActiveClass,
      routeName: key,
    };
  });
};

// Manage order step
export const disabledOrderStep = (orderType, isChange, isFactoryOrder) => {
  switch (orderType) {
    case OrderCreationType.STOCK:
      if (isChange && isFactoryOrder) {
        return {
          disabledSteps: [WizardSteps[WizardSteps.VehicleAccessories], WizardSteps[WizardSteps.Finance], WizardSteps[WizardSteps.Signing]]
        }
      } else {
        return {
          disabledSteps: [WizardSteps[WizardSteps.VehicleAccessories], WizardSteps[WizardSteps.Finance]]
        }
      }
    case OrderCreationType.DEMO:
      if (isChange && isFactoryOrder) {
        return {
          disabledSteps: [WizardSteps[WizardSteps.Finance], WizardSteps[WizardSteps.Signing]]
        }
      } else {
        return {
          disabledSteps: [WizardSteps[WizardSteps.Finance]]
        }
      }
    default: 
      return {
        disabledSteps: []
      }
  }
}

export const SalesWizardMenu = (props: ISalesWizardMenuProps) => {
  const activeStep = getActiveStep(props.rootUrl);
  const stepData = getStepData(activeStep, props.orderId, props.rootUrl);
  const [missingItems, setMissingItems] = React.useState<string[]>([]);
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false)
  const navigate = useNavigate();
  const { getVehicleData } = React.useContext(VehicleContext) ?? {};
  const isStockSaleOrder = isValidStockSaleOrder(props.order, props?.configuration)
  const { disabledSteps } = disabledOrderStep(props?.order?.lead?.orderType, props?.isChange, props?.isFactoryOrder);


  useEffect(() => {
    const items: string[] = [];
    if (!props.order?.customer?.bosCustomer) items?.push(MissingItem.Customer);
    if (!props.order?.vehicles || props.order?.vehicles?.length < 1) items?.push(MissingItem.Car);
    if (!props.calculationId && !props?.order?.chosenOffer?.id) items?.push(MissingItem.Offer);
    setMissingItems(items);
  }, [props?.order, props?.calculationId]);

  useEffect(() => {
    props.updateStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    setIsButtonDisable(props?.menuLoading)
  }, [props?.menuLoading])

  const isClickableLink = (index: number) => {
    return index === activeStep ? true : !props?.menuLoading;
  };

  const isSigningClickable = (index: number): boolean => {
    if (index !== WizardSteps.Signing) {
      return true;
    }
    return !missingItems?.length && props.isVehicleValidForReserveOrSign;
  };

  const isStepClickable = (index: number): boolean => {
    return (
      !!props.orderId &&
      props.hasSelectedVehicle &&
      isClickableLink(index) &&
      !props?.isUnavailablePimData &&
      isSigningClickable(index)
    );
  };

  const handleMenuClick = (stepdata: IStepMenuData) => {
    setIsButtonDisable(true);
    if (activeStep === WizardSteps?.['Vehicle'] || activeStep === WizardSteps?.['VehicleAccessories'] || activeStep === WizardSteps?.['Finance']) {
      props?.onClick(stepdata);
    } else {
      navigate(stepdata?.route);
    }
    setIsButtonDisable(false);
  };

  const isSelectedVehicleAvailable = () => {
    if (isStockSaleOrder) {
      return true;
    }
    if (props?.orderId) {
      return getVehicleData()?.modelId && getVehicleData()?.variantId;
    }
    return false;
  };

  const getCommonDisabledCondition = (key) => {
    return (
      props?.isUnavailablePimData ||
      isButtonDisable ||
      (key !== WizardSteps[WizardSteps.Vehicle] &&
        props.activeStep === PageSteps.START &&
        !isSelectedVehicleAvailable())
    );
  };

  const editOrderDisableKey = (key) => {
    if (isEditable(props?.order?.status) && key === WizardSteps[WizardSteps.Vehicle]) {
      return true;
    }
  }

  const getDisabledOrderStep = (key) => {
    return getCommonDisabledCondition(key) || disabledSteps.includes(key) || editOrderDisableKey(key)
  }

  const getSigningDisable= (key, index) => {
    return getCommonDisabledCondition(key) || !isSigningClickable(index) || disabledSteps.includes(key) || editOrderDisableKey(key)
  }

  return (
    <SalesWizardMenuWrapper>
      <StyledSalesWizardMenu>
        {getStepsKeys()?.map((key, index) =>
          isStepClickable(index) ? (
            <Button
              key={key}
              className={`
              small
              ${stepData?.[index]?.routeIsActiveClass}
            `}
              variant={'secondary'}
              colorVariant={'secondary'}
              onClick={() => handleMenuClick(stepData?.[index])}
              disabled={props?.order ? getDisabledOrderStep(key) : true}>
              {index + 1}. {menuTitles?.[key] || ''}
            </Button>
          ) : (
            <Button
              key={key}
              className={`
              small
              ${stepData?.[index]?.routeIsActiveClass}
            `}
              variant={'secondary'}
              colorVariant={'secondary'}
              onClick={() => handleMenuClick(stepData?.[index])}
              disabled={props?.order ? getSigningDisable(key, index) : true}>
              {index + 1}. {menuTitles?.[key] || ''}
            </Button>
          ),
        )}
      </StyledSalesWizardMenu>
      <SalesWizardButtonMenu
        orderId={props?.orderId}
        order={props?.order}
        calculationId={props?.calculationId}
        activeStep={props?.activeStep}
        isUnavailablePimData={props?.isUnavailablePimData}
        menuLoading={props?.menuLoading}
        onClickSigning={props?.onClickSigning}
        onClick={props?.onClick}
        isStockSaleOrder={isStockSaleOrder}
        stepData={stepData?.[stepData?.length - 1]}
        isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
        vehicleAvailabilityCode={props.vehicleAvailabilityCode}
        isChange={props.isChange}
        isFactoryOrder={props.isFactoryOrder}
      />
    </SalesWizardMenuWrapper>
  );
};
