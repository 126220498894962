import styled from 'styled-components';
import { black, bosColor, disabled, negative, typography } from '@next-components/common-styles';

export const AnalogSigningModalStyled = styled.div`
  .next-modal {
    max-height: calc(101vh - 16rem);
    .upload .file-drop {
      min-height: 5.625rem;
      .file-drop-target {
        min-height: 5.625rem;
      }
      .file-drop-target > div {
        min-height: 5.625rem;
        height: 5.625rem;
      }
    }
  }
  .footerButton {
    display: flex;
    place-items: end;
    gap: 1rem;
    justify-content: flex-start;
    .analogModalBtn {
      flex: 1 0 0;
      text-align: center;
      justify-content: center;
    }
  }
`;

export const AnalogSigningStepStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  &.disabled {
    color: ${disabled};
  }
  .contractPdfBtn {
    width: 100%;
  }
  .droppedFileDetails {
    color: ${bosColor.black};
  }
  .info {
    margin: 4px 0 8px;
  }
  .errorMsg {
    color: ${negative};
    font-size: 80%;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ellipsis div {
      background: ${black};
    }
  }
  .text-style {
    button {
      margin-bottom: 10px;
    }
  }

  .successMessageWrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .text-lg {
    font-size: 1.125rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
  .text-sm {
    font-size: 0.875rem;
    font-weight: ${typography.weight.semibold};
    line-height: 170%;
  }
  .display-xs {
    font-size: 1.5rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 165%;
  }
`;
