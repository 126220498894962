import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import CrossImage from './imgs/SolidCross.jpg';
import CheckImage from './imgs/SolidCheck.jpg';
import { IGetServiceContract } from 'sales-common';
import { SCMakeStyle } from './ServiceContractTable.style';
import MerCheckImage from './imgs/independent-dealer/mer_check.jpg';
import KiaCheckImage from './imgs/independent-dealer/kia_check.jpg';
import SrtCheckImage from './imgs/independent-dealer/srt_check.jpg';
import PeuCheckImage from './imgs/independent-dealer/peu_check.jpg';
import { MAKE } from 'next-common';
import { backgroundCard, bosColor } from '@next-components/common-styles';

export interface IServiceContractTable {
  pageBreak?: boolean;
  make?: string,
  dealerRole?: string,
  serviceContract?: IGetServiceContract;
}

const SCTableWrapperStyle: Style = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '10 20',
};
const SCTableTableRowStyle: Style = {
  display: 'flex',
  flexDirection: 'row',
};
const SCTableHeaderRowStyle: Style = {
  fontWeight: 'bold',
  backgroundColor: backgroundCard,
};
const SCTableTableRowItemStyle: Style = {
  display: 'flex',
  padding: '6px 8px',
  alignItems: 'center',
  fontStyle: 'normal',
  lineHeight: 'normal',
};
const SCTableTableRowItemValueStyle: Style = {
  justifyContent: 'center',
  textAlign: 'center',
};
const SCTableBodyStyle: Style = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: bosColor.white,
};

export const getHeaderPriceConfig = (contractTypeList) => {
  let priceConfigHeader = [{ header: 'Beskrivelse', key: 'coveringInfo', style: { ...SCTableTableRowItemStyle } }];

  if (contractTypeList && contractTypeList.length > 0) {
    contractTypeList.map((item) =>
      priceConfigHeader.push({ header: item.name, key: 'header_' + item?.id, style: { ...SCTableTableRowItemStyle, ...SCTableTableRowItemValueStyle } }),
    );
  }

  return priceConfigHeader;
};


const columnWidth: string[] = ['61.5%', '15.5%', '23%'];

const compIcon = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return SrtCheckImage;
      case MAKE.Mercedes:
        return MerCheckImage;
      case MAKE.Peugeot:
        return PeuCheckImage;
      case MAKE.Kia:
        return KiaCheckImage;
      default:
        return CheckImage;
    }
  } else {
    return CheckImage;
  }
}

const SCTableHeader = ({ cols, dealerRole, make }) => {
  const headerColStyle = SCMakeStyle[dealerRole][make]['headerCol']
  return (
    <View style={{ ...SCTableTableRowStyle, ...SCTableHeaderRowStyle }}>
      {cols.map((col, index) => (
        <Text 
          style={{
            ...col.style,
            ...headerColStyle,
            width: columnWidth[index]
          }}
          key={`${col.key}-${index}`}
        >
          {col.header}
        </Text>
      ))}
    </View>
  )
};

export const CompareIcon = (props: any) => {
  const item = props.serviceContract.contractTypeCoveringList.filter(
    (listItem) =>
      listItem.coveringInfoId === props.coveringListItem.id && listItem.contractTypeId === props.contractTypeId,
  );

  return (
    <Image source={item.length ? compIcon(props?.dealerRole, props?.make) : CrossImage } style={{ height: 16, width: 16 }} />
  );
};

const SCTableBody = ({ rowData, cols, dealerRole, make }) => {
  const checkCols = cols?.slice(1);
  const beskrivelseColStyle = SCMakeStyle[dealerRole][make]['beskrivelseCol'];
  const noDataTxt = 'Servicekontrakter er ikke tilgjengelig for denne modellen';
  if (!rowData || !rowData.coveringInformationList || rowData.coveringInformationList.length === 0) {
    return (
      <View style={SCTableBodyStyle}>
        <View
          style={{
            ...SCTableTableRowStyle,
            borderBottom: `1px solid ${backgroundCard}`,
          }}
        >
          <Text style={{ ...SCTableTableRowItemStyle, ...beskrivelseColStyle, width: '100%' }}>
            {noDataTxt}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={SCTableBodyStyle}>
      {rowData?.coveringInformationList?.map((listItem, key) => (
        <View
          style={{
            ...SCTableTableRowStyle,
            borderBottom: rowData?.length === key + 1 ? 'none' : `1px solid ${backgroundCard}`,
          }}
          key={key}
        >
          <Text style={{ ...SCTableTableRowItemStyle, ...beskrivelseColStyle, width: '61.5%' }}>
            {listItem.coveringInfo}
          </Text>
          {checkCols?.map((header, index) => (
            <View style={{ ...SCTableTableRowItemStyle, ...SCTableTableRowItemValueStyle, width: columnWidth[index + 1] }} key={`key-${index}`}>
              <CompareIcon
                make={make}
                dealerRole={dealerRole}
                serviceContract={rowData}
                coveringListItem={listItem}
                contractTypeId={+header?.key?.split('_')?.[1]}
              />
            </View>
          ))}
        </View>
      ))}
    </View>
  )
};

export const ServiceContractTable = ({ pageBreak, serviceContract, dealerRole, make }: IServiceContractTable) => {
  const dynamicColumns = getHeaderPriceConfig(serviceContract?.contractTypeList);
  return (
    <View style={SCTableWrapperStyle} break={pageBreak}>
      <Text style={SCMakeStyle[dealerRole][make]['heading']}>Sammenligning av avtalene</Text>
      <SCTableHeader cols={dynamicColumns} dealerRole={dealerRole} make={make} />
      <SCTableBody rowData={serviceContract} cols={dynamicColumns} dealerRole={dealerRole} make={make} />
    </View>
  );
};
