import React from 'react';
import SalesWizardPage from '../../pages/SalesWizardPage/SalesWizardPage';
import { IVehicleUpdate } from './SalesWizard';
import { IConfiguration } from 'next-common';

interface ISalesWizardMasterProps {
  dealerRole?: string;
  isTestUser?: boolean;
  configuration?: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
  redirectPath?: string
}

const SalesWizardMaster = (props: ISalesWizardMasterProps) => {

  return <SalesWizardPage manageSocket={props.manageSocket} configuration={props.configuration} dealerRole={props.dealerRole} isTestUser={props.isTestUser} redirectPath={props.redirectPath}/>;
};

export default SalesWizardMaster;
