import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const PreparationPaneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const PreparationPaneHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3.title {
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
`;

export const PreparationPaneList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .orderEventItem {
    width: 100%;

    &.message-box-info {
      .message-bar-body {
        width: 100%;
      }
    }
    &.fail {
      svg {
        background-color: ${bosColor.destructive};
        border-radius: 100%;

        path {
          fill: ${bosColor.white};
        }
      }
    }
  }
  .noRecords {
    padding: 0.75rem 1.5rem;
    background: white;
    border: 1px solid ${bosColor.neutralPlus};
    border-radius: 0.25rem;
    text-align: center;
    color: ${bosColor.black};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
  }
`;

export const WorkOrderEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .divider {
    width: 100%;
    height: 1px;
    &.success {
      background: ${bosColor.komorebi};
    }
    &.fail {
      background: ${bosColor.poppy60};
    }
  }
  .eventSection {
    display: flex;

    &.header {
      flex-direction: column;

      .eventTitle {
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.extrabold};
        line-height: 175%;
      }
      .dateTime {
        color: ${bosColor.concrete};
        font-size: 0.875rem;
        font-weight: ${typography.weight.semibold};
        line-height: 170%;
      }
    }
    &.eventContent {
      display: flex;
      gap: 1.5rem;

      .eventStatusContent {
        display: grid;
        grid-template-columns: 18.75rem 2fr;
        gap: 0.75rem;
        flex: 1 0 0;

        .statusLabel {
          color: ${bosColor.concrete};
          font-size: 0.875rem;
          font-style: normal;
          font-weight: ${typography.weight.semibold};
          line-height: 170%;
        }
        .statusValue {
          color: ${bosColor.concrete};
          font-size: 1rem;
          font-style: normal;
          font-weight: ${typography.weight.extrabold};
          line-height: 175%;
        }
      }
      .eventAction {
        align-items: flex-end;

        .workOrderEventAction {
          width: fit-content;
          padding: 0.75rem 1rem;
        }
      }
    }
  }
`;
