import React from 'react';
import muliFont from '../../../../assets/fonts/muli/Muli-Regular.ttf';
import muliFontLight from '../../../../assets/fonts/muli/Muli-Light.ttf';
import muliFontExtraLight from '../../../../assets/fonts/muli/Muli-ExtraLight.ttf';
import muliFontSemiBold from '../../../../assets/fonts/muli/Muli-SemiBold.ttf';
import muliFontBold from '../../../../assets/fonts/muli/Muli-Bold.ttf';
import muliFontExtraBold from '../../../../assets/fonts/muli/Muli-ExtraBold.ttf';
import { Font, Document, Page, View } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';
import {
  Header,
  Footer,
  CarInfo,
  Accessories,
  FinanceOptions,
  EquipmentList,
  TradeInVehicles,
  PdfMessagebar,
  ServiceContractTable,
} from './parts';
import {
  IOrder,
  IBosApiDealerInfoData,
  IVehicle,
  IVehicleConfiguration,
  FinancingType,
  IPackageEquipmentItems,
  MAKE,
  getCalculationByType,
  isElectricCar,
  MBApiCalculationFlag,
  INextArticle,
  IVatCalculation,
  ICalculation,
} from 'next-common';
import { IAccessoryConfig, IOrderOffers } from './SalesOfferPdf';
import { PriceChangeInfo } from './parts/PriceChangeInfo';
import { ICartProps } from '@next-components/cart';
import { isOldOfferExpire } from '../../../../utils';
import smartFont from '../../../../assets/fonts/smart/SmartNext-Regular.otf';
import smartBold from '../../../../assets/fonts/smart/SmartNext-Bold.otf';
import smartSansFont from '../../../../assets/fonts/smart/SmartSans-Regular.otf';
import smartSansFontBold from '../../../../assets/fonts/smart/SmartSans-Bold.otf';

import kiaFont from '../../../../assets/fonts/kia/KiaSignatureOTFRegular.otf';
import kiaBold from '../../../../assets/fonts/kia/KiaSignatureOTFBold.otf';
import kiaLight from '../../../../assets/fonts/kia/KiaSignatureOTFLight.otf';

import mbFont from '../../../../assets/fonts/mb/MBCorpoSText-Regular-Web.woff';
import mbBold from '../../../../assets/fonts/mb/MBCorpoSText-Bold-Web.woff';
import peugeotFont from '../../../../assets/fonts/peugeot/Peugeot_New-Regular.woff';
import peugeotLight from '../../../../assets/fonts/peugeot/Peugeot_New-Light.woff';
import peugeotExtraLight from '../../../../assets/fonts/peugeot/Peugeot_New-ExtraLight.woff';
import peugeotBold from '../../../../assets/fonts/peugeot/Peugeot_New-Bold.woff';
import { ICommonSalesOfferPdfProps } from '../../sales-order-view/OrderPane';
import { ICentralisedProperties } from '../../../../common';
import { findSelectedServiceContract, isValidStockSaleOrder } from '../../../../utils';
import { defaultTo } from 'lodash';
import { IGetServiceContract } from 'sales-common';
import { bosColor, mercedesColor } from '@next-components/common-styles';

Font.register({
  family: 'Muli',
  format: 'truetype',
  fonts: [
    { src: muliFont, fontWeight: 'normal' },
    { src: muliFontLight, fontWeight: 'light' },
    { src: muliFontExtraLight, fontWeight: 'extralight' },
    { src: muliFontSemiBold, fontWeight: 'semibold' },
    { src: muliFontBold, fontWeight: 'bold' },
    { src: muliFontExtraBold, fontWeight: 'extrabold' },
  ],
});

Font.register({
  family: 'Smart',
  format: 'truetype',
  fonts: [
    { src: smartFont, fontWeight: 'normal' },
    { src: smartBold, fontWeight: 'bold' },
    { src: smartSansFont, fontWeight: 'normal' },
    { src: smartSansFontBold, fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'Mercedes',
  format: 'truetype',
  fonts: [
    { src: mbFont, fontWeight: 'normal' },
    { src: mbBold, fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'Peugeot',
  format: 'truetype',
  fonts: [
    { src: peugeotFont, fontWeight: 'normal' },
    { src: peugeotLight, fontWeight: 'light' },
    { src: peugeotExtraLight, fontWeight: 'extralight' },
    { src: peugeotBold, fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'Kia',
  format: 'truetype',
  fonts: [
    { src: kiaFont, fontWeight: 'normal' },
    { src: kiaBold, fontWeight: 'bold' },
    { src: kiaLight, fontWeight: 'light' },
  ],
});

export interface IOfferDocumentProps extends ICommonSalesOfferPdfProps, ICentralisedProperties {
  order: IOrder;
  vehicle: IVehicle;
  vehicleImage: SourceObject;
  vehicleConfig: IVehicleConfiguration;
  dealer: IBosApiDealerInfoData;
  accessories: IAccessoryConfig[];
  isSerialNo: boolean;
  disablePdfLogo: boolean;
  orderOffers: IOrderOffers;
  cartData?: ICartProps;
  standredPackageEquipmentList?: IPackageEquipmentItems[];
  modelPackagesEquipmentList?: IPackageEquipmentItems[];
  infoAgent: string;
  articles?: INextArticle[];
  carType?: string;
  dealerRole?: string;
  serviceContract?: IGetServiceContract;
}

export interface IServiceContract {
  name: string;
  price: number;
  mva: number;
}

const getTotalPrice = (vatCalculation: IVatCalculation, serviceContract: IServiceContract) => {
  if (!serviceContract) return defaultTo(vatCalculation?.salesTotalIncludingVatAndTax, 0);
  return defaultTo(vatCalculation?.salesTotalIncludingVatAndTax, 0) + defaultTo(serviceContract?.price, 0);
};

const getFontFamily = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Mercedes:
        return 'Mercedes';
      case MAKE.Smart:
        return 'Smart';
      case MAKE.Kia:
        return 'Kia';
      case MAKE.Peugeot:
        return 'Peugeot';
      default:
        return 'Muli';
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return 'Smart';
      default:
        return 'Muli';
    }
  }
};

export const OfferDocument = (props: IOfferDocumentProps) => {
  const isOfferExpire = isOldOfferExpire(
    props?.order?.status,
    props?.order?.vatCalculation?.salesTotalExcludingVatAndTax,
  );
  const calculation = getCalculationByType(props?.order, FinancingType.CASH);
  const serviceContract = calculation?.serviceContract?.isLoanPaymentIntervalSelected
    ? findSelectedServiceContract(calculation?.serviceContract)
    : null;
  const totalPrice = getTotalPrice(props?.order?.vatCalculation, serviceContract);
  const fontFamily = getFontFamily(props?.dealerRole, props?.order?.lead?.make);
  const totalMVA =
    defaultTo(props?.order?.vatCalculation?.calculatedVatOnDeductableItems, 0) +
    defaultTo(props?.order?.vatCalculation?.calculatedVatOnNonDeductableItems, 0) +
    defaultTo(serviceContract?.mva, 0);

  const isElectric = isElectricCar(props?.order?.pimSnapshot, props?.vehicleConfig);
  const isStockSaleOrder = isValidStockSaleOrder(props?.order, props?.configuration);
  const showCarInfo = () => {
    const propsObject = {
      vehicleImage: props?.vehicleImage,
      vehicle: props?.vehicle,
      config: props?.vehicleConfig,
      totalPrice,
      discount: props?.order?.discount,
      accessories: props?.accessories,
      isSerialNo: props?.isSerialNo,
      order: props?.order,
      cartData: props?.cartData,
      standredPackageEquipmentList: props?.standredPackageEquipmentList ?? [],
      modelPackagesEquipmentList: props?.modelPackagesEquipmentList ?? [],
      isOfferExpire,
      vatPrices: props?.vatPrices,
      serviceContract,
      totalMVA,
      isElectric,
      articles: props?.articles,
      carType: props?.carType,
      dealerRole: props?.dealerRole,
      isStockSaleOrder,
    };
    if (isStockSaleOrder || props?.vehicleConfig) {
      return <CarInfo {...propsObject} />;
    } else {
      return null;
    }
  };
  const financeOptionsProps = {
    ...props?.orderOffers,
    isOfferExpire,
    totalMVA,
    totalPrice,
    isElectric,
    ...props?.order,
  };

  const getTextColor = (dealer: string, brand: string) => {
    if (dealer === 'INDEPENDENT_DEALER') {
      return mercedesColor.black;
    } else {
      return bosColor.black
    }
  };

  const isServiceContractSelectedInOffers = (offers: ICalculation[]): boolean => {
    if (offers?.length > 0) {
      return offers.some(offer => offer?.serviceContract && typeof offer.serviceContract === 'object');
    }
    return false;
  }

  return (
    <Document>
      <Page 
        style={{ 
          fontFamily: fontFamily, 
          paddingBottom: 90, 
          color: getTextColor(props?.dealerRole, props?.order?.lead?.make) 
        }} wrap={true}
      >
        <Header order={props?.order} disablePdfLogo={props?.disablePdfLogo} dealerRole={props?.dealerRole} />
        {showCarInfo()}
        {isOfferExpire ? <Accessories accessories={props?.accessories} order={props?.order} /> : null}
        <EquipmentList
          dealerRole={props?.dealerRole}
          make={props?.order?.lead?.make}
          config={props?.vehicleConfig}
          accessories={props?.accessories}
          standredPackageEquipmentList={props?.standredPackageEquipmentList ?? []}
          modelPackagesEquipmentList={props?.modelPackagesEquipmentList ?? []}
          isStockSaleOrder={isStockSaleOrder}
        />
        <TradeInVehicles
          tradeIns={props?.order?.tradeInVehicles}
          dealerRole={props?.dealerRole}
          make={props?.order?.lead?.make}
        />
        <FinanceOptions
          {...{
            ...financeOptionsProps,
            make: props?.order?.lead?.make,
            pageWrap: props?.standredPackageEquipmentList?.length > 0 || props?.order?.tradeInVehicles?.length > 0,
            pageBreak: props?.order?.tradeInVehicles?.length === 0,
            salesTotalIncludingVatAndTax: props?.order?.vatCalculation?.salesTotalIncludingVatAndTax || 0,
            dealerRole: props?.dealerRole,
          }}
        />
        {isServiceContractSelectedInOffers(props?.order?.offers) && (
          <ServiceContractTable 
            dealerRole={props?.dealerRole}
            make={props?.order?.lead?.make}
            serviceContract={props?.serviceContract}
            pageBreak 
          />
        )}
        {props?.order?.externalTaxCalculation === MBApiCalculationFlag?.FETCH_FAILED && (
          <View
            break={
              props?.standredPackageEquipmentList?.length === 0 && props?.order?.tradeInVehicles?.length === 0
                ? true
                : false
            }>
            {props?.order?.externalTaxCalculation === MBApiCalculationFlag?.FETCH_FAILED && (
              <PdfMessagebar
                dealerRole={props?.dealerRole}
                make={props?.order?.lead?.make}
                text="Engangsavgiften i dette tilbudet er kun et estimat. En ny kalkulering må gjøres før kontrakt kan signeres.Vi tar derfor forbehold om at totalprisen kan endres når engangsavgiften kalkuleres på nytt"
              />
            )}
          </View>
        )}
        <PriceChangeInfo
          pageBreak={
            (props?.vehicleConfig?.model?.make === 'KIA' &&
              props?.orderOffers?.loan?.length === 0 &&
              props?.orderOffers?.leasing?.length === 0 &&
              (props?.standredPackageEquipmentList?.length === 0 ||
                props?.standredPackageEquipmentList?.length <= 27) &&
              (props?.order?.tradeInVehicles?.length === 0 || props?.order?.tradeInVehicles === null)) ||
            props?.standredPackageEquipmentList?.length <= 20
          }
        />
        <Footer
          dealer={props?.dealer}
          user={props?.order?.user}
          createdAt={props?.order?.createdAt}
          expiresAt={props?.order?.expiresAt}
          prerequisiteComment={props?.order?.prerequisite ?? ''}
          make={props?.order?.lead?.make}
          infoAgent={props?.infoAgent}
          dealerRole={props?.dealerRole}
        />
      </Page>
    </Document>
  );
};
