import React, { useEffect, useState } from 'react';
import {
  PreparationPaneHeader,
  PreparationPaneList,
  PreparationPaneStyled,
  WorkOrderEventWrapper,
} from './PreparationPane.styled';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IconType } from '@next-components/bos-icon';
import { DateTimeBlock } from '../../sales-common/DateTimeBlock';
import { Button } from '@next-components/button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ERROR_POLICY, addAppointmentToDarsMutationParsed } from '../../../../common';
import { findOrderByIdConcised } from '../../../../graphql';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { IConfiguration } from 'next-common';

const useOrderQuery = (orderId) => {
  const [orderData, { loading, data }] = useLazyQuery(findOrderByIdConcised, {
    variables: { id: orderId },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (orderId) {
      orderData();
    }
  }, [orderId, orderData]);

  return { data: data?.findOrderById, loading, refetchOrder: orderData };
};

export interface IPreparationPaneProps {
  orderId: string;
  configuration: IConfiguration;
}

export const PreparationPane = (props: IPreparationPaneProps) => {
  const { data: isData, loading, refetchOrder } = useOrderQuery(props.orderId);
  console.log("🚀 ~ PreparationPane ~ isData:", isData)
  const [addAppointmentToDars] = useMutation(addAppointmentToDarsMutationParsed);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (orderId, serialNo) => {
    setIsLoading(true);
    const variables = { orderId: orderId ?? '', serialNo: serialNo ?? '' };
    const { data } = await addAppointmentToDars({ variables });
    if (data?.addAppointmentToDars) {
      await refetchOrder();
    }
    setIsLoading(false);
  };

  const sortedEvents = [...(isData?.workOrderEvents || [])].sort(
    (a, b) => new Date(b.doneAt).getTime() - new Date(a.doneAt).getTime(),
  );

  const latestEvent = sortedEvents[0];
  console.log("🚀 ~ PreparationPane ~ latestEvent:", latestEvent)
  const shouldShowButtonForLatest = latestEvent?.workOrderStatus === 'FAILURE' && !latestEvent?.aoNumber;

  const renderWorkOrderSuccessEvents = (itemParam, isLatestEvent) => (
    <WorkOrderEventWrapper>
      <div className="eventSection header">
        <span className="eventTitle">
          {itemParam?.workOrderStatus === 'SUCCESS'
            ? `Arbeidsordre ${itemParam?.aoNumber} opprettet`
            : 'Arbeidsordre feilet'}
        </span>
        <DateTimeBlock dateTime={itemParam?.doneAt} shortDate />
      </div>
      <div className={`divider ${itemParam.workOrderStatus === 'SUCCESS' ? 'success' : 'fail'}`}></div>
      <div className="eventSection eventContent">
        <div className="eventStatusContent">
          <div className="eventStatus">
            <span className="statusLabel">Status</span>
            <p className="statusValue">{itemParam?.workOrderStatus ?? 'Ukjent'}</p>
          </div>
          <div className="eventStatusMessage">
            <span className="statusLabel">Statusmelding</span>
            <p className="statusValue">{itemParam?.statusMessage ?? '-'}</p>
          </div>
        </div>

        {props?.configuration?.features?.workOrder ? isLatestEvent && shouldShowButtonForLatest && (
          <div className="eventAction">
            <Button
              colorVariant="secondary"
              className="workOrderEventAction"
              isLoading={isLoading}
              onClick={() => handleClick(props?.orderId, isData?.vehicles[0]?.serialNo)}>
              Send arbeidsordre på nytt
            </Button>
          </div>
        ): null}
      </div>
    </WorkOrderEventWrapper>
  );

  return (
    <PreparationPaneStyled>
      <PreparationPaneHeader>
        <h3 className="title">Klargjøring</h3>
      </PreparationPaneHeader>
      <PreparationPaneList>
        {sortedEvents.length ? (
          sortedEvents.map((item, key) => (
            <Messagebar
              key={key}
              messageBoxInfo
              alertIcon={item.workOrderStatus === 'SUCCESS' ? IconType.CircleCheck : IconType.Close}
              severityLevel={
                item.workOrderStatus === 'SUCCESS' ? MessagebarSeverityLevel.SUCCESS : MessagebarSeverityLevel.ERROR
              }
              title={renderWorkOrderSuccessEvents(item, key === 0)}
              className={`orderEventItem ${item.workOrderStatus === 'SUCCESS' ? 'success' : 'fail'}`}></Messagebar>
          ))
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <div className="noRecords">Ingen arbeidsordre</div>
        )}
      </PreparationPaneList>
    </PreparationPaneStyled>
  );
};
