import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalInstance } from './msalConfig';

export const getTokenLocalStorage = () => {
  return localStorage?.getItem('msal-token') ?? null;
};

export const setTokenLocalStorage = (value: string) => {
  localStorage?.setItem('msal-token', value);
};

export const getCachedToken = () => {
  const token = getTokenLocalStorage();
  if (token === null || token === undefined) {
    acquireToken(msalInstance).then((updateToken) => {
      return updateToken;
    });
  } else {
    return token;
  }
};

export const acquireToken = async (msalInstance: IPublicClientApplication = null) => {
  try {
    if (msalInstance === null) {
      throw Error('MSAL Instance not found.');
    }

    const account = msalInstance?.getActiveAccount() ?? null;
    if (!account) {
      console.info('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      return;
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    setTokenLocalStorage(response?.accessToken);
    return response?.accessToken;
  } catch (error) {
    console.error(`Acquire token error - ${error}`);
  }
};
