
export const SCMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'heading': {
                fontWeight: 'semibold',
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'MER': {
            'heading': {
                fontWeight: 'semibold',
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'KIA': {
            'heading': {
                fontWeight: 'semibold',
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'PEU': {
            'heading': {
                fontWeight: 'semibold',
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'SRT': {
            'heading': {
                fontWeight: 700,
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'MER': {
            'heading': {
                fontWeight: 700,
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'KIA': {
            'heading': {
                fontWeight: 'bold',
                fontSize: 12,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 10,
            },
            'beskrivelseCol': {
                fontSize: 10,
                fontWeight: 'normal'
            }
        },
        'PEU': {
            'heading': {
                fontWeight: 700,
                fontSize: 10,
                fontStyle: 'normal',
                lineHeight: 'normal',
                paddingBottom: '8px',
            },
            'headerCol': {
                fontSize: 8,
            },
            'beskrivelseCol': {
                fontSize: 8,
                fontWeight: 'normal'
            }
        }
    }
}