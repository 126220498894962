import {
  orderEventFragment,
  orderQueryFilterFields,
  paginationFields,
  referenceFragment,
  sortingFields,
  workOrderEventFragment,
} from 'next-common';

const orderFragment = `
fragment order on Order {
  id
  createdAt
  deliveredAt
  expiresAt
  actualExpiresAt
  financingType
  signedAt
  status
  chosenOffer {
    id
  }
  pimSnapshot {
    make
    series
  }
  customer {
    id
    customerType
    bosCustomer {
      id
      customerType
      email
      firstName
      lastName
      organizationName
      phoneMobile
    }
  }
  orderReference {
    ...reference
  }
  events {
    ...orderEvent
  }
  workOrderEvents {
    ...workOrderEvent
  }
  user {
    ...user
  }
  vehicles {
    ...vehicle
  }
  reserveDetails {
    vehicleReserveStatus
  }
}
`;
const vehicleFragment = `
  fragment vehicle on Vehicle {
    id
    serialNo
    make
    model {
      id
      name
    }
  }
`;

const userFragment = `
  fragment user on User {
    id
    email
    ident
    employeeId
    firstName
    lastName
  }
`;

export const myOverviewOrderQuery = `
  query orderQuery($input: OrderQueryInput) {
    orderQuery(input: $input) {
      pagination {
        ${paginationFields}
      }
      sorting {
        ${sortingFields}
      }
      filters {
        ${orderQueryFilterFields}
      }
      results {
        ...order
      }
    }
  }
  ${orderFragment}
  ${orderEventFragment}
  ${workOrderEventFragment}
  ${referenceFragment}
  ${userFragment}
  ${vehicleFragment}
`;
